import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginService } from '../services/login.service';
import { ToursService } from '../services/tours.service';
import { PropertyService } from '../services/property.service';
import { ClientService } from '../services/client.service';
import { PhotosService } from '../services/photos.service';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Client } from '../client';
import { Property } from '../propertySearch';
import { environment } from '../../environments/environment';
import { checkForAuthError } from '../utils';
import { AddressAutocompleteComponent } from '../address-autocomplete/address-autocomplete.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { CheckmarkComponent } from '../checkmark/checkmark.component';
import { ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, Validators, ReactiveFormsModule,FormsModule } from '@angular/forms';
import {NgFor} from '@angular/common';
import { Category, Poicategory, Poisubcategory } from '@app/interface/poi';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarMessageComponent } from '@app/components/snackbar-message.component';

@Component({
  selector: 'app-new-client-form',
  templateUrl: './new-client-form.component.html',
  styleUrls: ['./new-client-form.component.css'],
})
export class NewClientFormComponent implements OnInit {
  public step = 0;

  setStep(index: number,state:boolean) {
    this.step = index;
    this.poiCategory[index]['expanded']=state;
  }

  // nextStep() {
  //   this.step++;
  // }

  // prevStep() {
  //   this.step--;
  // }

  /**category**/
  poiCategory: Poicategory[] = [];
  selectedCategories: string[] = [];
  selectedSubcategories: string[][] = [];
  selectedCategoriesName: any[] = [];

  updateAllComplete(catIndex: number) {
    this.poiCategory[catIndex].checked = this.poiCategory[
      catIndex
    ].subcategory.every((t) => t.isSelected);
  }

  // someComplete(index: number): boolean {
  //   if (this.poiCategory[index].subcategory == null) {
  //     return false;
  //   }
  //   return (
  //     this.poiCategory[index].subcategory.filter((t) => t?.checked).length >
  //       0 && !this.poiCategory[index].checked
  //   );
  // }
  // setAll(categoryChecked: boolean, category: Poicategory, index: number) {
  //   /* Selected */
  //   if (categoryChecked) {
  //     this.categoryControl.push(category.id);
  //     this.poiCategory[index].subcategory.forEach(
  //       (t) => t.isSelected == category.checked
  //     );
  //     this.poiCategory[index].checked = category.checked;
  //   } else {
  //     const index = this.categoryControl.indexOf(category.id);
  //     if (index > -1) {
  //       // only splice array when item is found
  //       this.categoryControl.splice(index, 1); // 2nd parameter means remove one item only
  //     }
  //   }
  // }
  setAllSubCategory(
    subCategoryChecked: boolean,
    Category: Poicategory,
    subCategory: Poisubcategory,
    index: number
  ) {
    const categoryId = Category.id;
    const subcategoryId = subCategory.id;
    const categoryIndex = this.selectedCategories.indexOf(categoryId);
    if(this.selectedCategoriesName.some(item => item.id === Category.id)){
      // If included, find the index of the existing category object
      const index = this.selectedCategoriesName.findIndex(cat => cat.id === Category.id);
      // Replace the existing object with the new one
      if (index !== -1) {
          this.selectedCategoriesName[index] = { ...Category };
      }
    }else{
      this.selectedCategoriesName.push(Category);
    }
    
    if(subCategoryChecked){
      this.poiCategory.find(cat=>cat.id===categoryId).checked=subCategoryChecked;
      if (categoryIndex === -1) {
        this.selectedCategories.push(categoryId);
        this.selectedSubcategories.push([subcategoryId]); // Create a new array for subcategories
      } else {
        // Category is already selected, check if the subcategory is selected
        if(categoryIndex==0 && this.selectedSubcategories.length==0){
          let data =this.selectedSubcategories[categoryIndex];
          this.selectedSubcategories[categoryIndex]=data.concat([subcategoryId]);
        } else{
          // Subcategory is not selected, add it to the selected subcategories for the category
          let data =this.selectedSubcategories[categoryIndex];
          this.selectedSubcategories[categoryIndex]=data.concat([subcategoryId]);
        }
      }
    }else{
        const subcategoryIndex1=  this.selectedSubcategories[categoryIndex].indexOf(subcategoryId);
       // Subcategory is already selected, remove it from the selected subcategories
       this.selectedSubcategories[categoryIndex].splice(subcategoryIndex1, 1);
       if (this.selectedSubcategories[categoryIndex].length === 0) {
        this.poiCategory.find(cat=>cat.id===categoryId).checked=subCategoryChecked;
        this.selectedCategories.splice(categoryIndex, 1);
        this.selectedSubcategories= this.selectedSubcategories.filter(subArray => subArray.length > 0)
        }
    }
  }

  isCategoryHighlighted(category): boolean {
    return category.subcategory.some(sub => sub.checked);
  }

  clientProfile() {
    this.router.navigate(['/clientCreate-profile']);
  }
  private imagePlaceholder = environment.imagePlaceholder;

  error: string = null;

  selectedFile: File = null;

  FirstName: string;
  LastName: string;
  Image: any;
  email: any;
  phone: any;
  address: any;
  city: any;
  zip: any;
  state: any;

  newClient: Client = {
    id: null,
    firstName: null,
    lastName: null,
    phone: null,
    email: null,
    checked: false,
    address: [
      {
        fullAddress: '',
        streetNumber: null,
        streetName: '',
        unitNumber: '',
        zip: null,
        city: '',
        state: '',
        country: '',
      },
    ],
    picture: this.imagePlaceholder,
    timeframe: null,
    clientFavorites: null,
    properties: null,
    clientcategory: null,
  };

  timeframes: any[] = [];

  /**
 	 A list of properties that have been passed along from an SSO login from MLS
	*/
  mlsList: [string] = null;

  /**
 	 Which MLS did the list originate from?
	*/
  mlsName: string = null;

  /**
 	 The properties as pulled from the mlsList query parameter
	*/
  trailingProperties: Property[] = [];

  /**
 	 Value for when the spinner should be shown
	*/
  showSpinner = false;

  showCheck = false;

  /**
 	 Client id will be populated by route `client/:clientId` when we arrive here for an existing client
	*/

  emailControl = new UntypedFormControl('');
  firstNameControl = new UntypedFormControl('');
  lastNameControl = new UntypedFormControl('');
  phoneControl = new UntypedFormControl('');
  timeframeControl = new UntypedFormControl('');
  categoryControl = [];
  subcategoryControl = [];

  private _clientId: number = null;
  set clientId(newValue: number) {
    this._clientId = newValue;

    if (this.clientId != null) {
      this.fetchExistingClient();
    }
  }
  get clientId(): number {
    return this._clientId;
  }

  /**
 	 Which logo to use
	*/
  logo = './assets/livepad-logo-black.png';

  /**
 	 Extra styling options for the image
	*/
  logoStyle = {
    height: '40px',
    opacity: '0.2',
  };

  buttonStyle = {
    color: 'white',
    'text-align': 'center',
    'font-family': "'Dunbar Tall', sans-serif",
    'letter-spacing': '0px',
    'font-size': '20px',
    height: '60px',
    width: 'auto',
  };

  /**
 	 Toggle for when the agent needs to edit an existing client
	*/
  editButtonPressed = false;

  /**
	 Are there properties following the user from an MLS entrance?
	*/
  showTrailingProperties() {
    return this.mlsList != null;
  }
  /** used for getall category */

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private clientService: ClientService,
    private propertyService: PropertyService,
    private toursService: ToursService,
    private domSanitizer: DomSanitizer,
    private photosService: PhotosService,
    private cdr: ChangeDetectorRef,
    private changeDetector: ChangeDetectorRef,
    private snackBar:MatSnackBar,
    private gaService: GoogleAnalyticsService
  ) {
    this.populateTimeframes();
  }

  ngOnInit(): void {
    this.FirstName = this.newClient.firstName;
    this.LastName = this.newClient.lastName;
    this.Image = this.newClient.picture;
    this.email = this.newClient.email;
    this.phone = this.newClient.phone;
    this.address = this.newClient.address[0].fullAddress;
    this.city = this.newClient.address[0].city;
    this.zip = this.newClient.address[0].zip;
    this.state = this.newClient.address[0].state;

    // Get any properties that have followed us from the tour flow
    this.fetchTrailingProperties();

    // Get the existing client information if it is accessible by this agent
    this.route.params.subscribe((params) => {
      this.clientId = params['clientId'];
    });
    this.getAllPoiList();
  }
  /*** add checked field in sub category */
  addCheckedProperty(data: any[]): Poicategory[] {
    return data.map((item) => {
      const newItem = { ...item, checked: false };
      if (newItem.subcategory) {
        newItem.subcategory = this.addCheckedProperty(newItem.subcategory);
      }
      return newItem;
    });
  }

  getAllPoiList() {
    this.clientService.getPoiCategory().subscribe({
      next: (res: Poicategory) => {
        let data = this.addCheckedProperty(res['data']['getCatSubCategories']);
        data.sort((a, b) => a.name.localeCompare(b.name));
        data.forEach(category => {
          category['expanded']=false;
          category.subcategory.sort((a, b) => a.name.localeCompare(b.name));
        });
        this.poiCategory = data;
      },
      error: (err: any) => {
        console.log('error-object', err);
      },
    });
  }
  @ViewChild('Email') Email: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('Timeframe') Timeframe: ElementRef;

  onKey1(event: KeyboardEvent) {
    this.onKey(event, this.Email);
  }
  onKey2(event: KeyboardEvent) {
    console.log("onkey 2 event triggers")
    this.onKey(event, this.Timeframe);
  }

  onKey(event: KeyboardEvent, targetElement: ElementRef) {
    event.preventDefault();
    if (event.key === "Tab") {
        const targetValue = targetElement === this.Email ? "Email" : "Timeframe";
        console.log("target value will be",targetValue);
        targetElement.nativeElement.value = targetValue;
        this.changeDetector.detectChanges();
        targetElement.nativeElement.focus();
    }
}

  focusOnTimeframe() {
    const timeframeHeading = document.querySelector('.section-header h2 span');
    if (timeframeHeading) {
      (timeframeHeading as HTMLElement).focus();
    }
  }

  /**
 	 Used to toggle disabled fields
	*/
  isEditing() {
    return this.clientId != null && this.editButtonPressed == true;
  }

  /**
 	 A new client
	*/
  isNewClient() {
    return this.clientId == null;
  }

  isValidImageUrl(url: string): boolean {
    // const regex =
    //   /^(https:\/\/d3o40wwl2vh905\.cloudfront\.net\/assets\/images\/avatar\/clients\/\d+_\d+\.(jpg|png|jpeg))|(blob:.+)$/i;
    const regex = /^(https:\/\/dkx2r6ndffbfa\.cloudfront\.net\/assets\/images\/(DEV|STAGE|UAT|PROD)\/avatar\/clients\/\d+_\d+\.(jpg|png|jpeg))|(blob:.+)$/i;
    return regex.test(url);
  }

  getActionButtonTitle() {
    if (this.isEditing()) {
      return 'SAVE';
    } else {
      return 'CREATE';
    }
  }

  setDefaultTimeframeValue(): void {
    this.timeframeControl.setValue(this.newClient.timeframe);
  }

  /**
 	 Press the edit button on an existing client
	*/
  toggleEdit() {
    this.FirstName = this.newClient.firstName;
    this.LastName = this.newClient.lastName;
    this.Image = this.newClient.picture;
    this.email = this.newClient.email;
    this.phone = this.newClient.phone;
    this.address = this.newClient.address[0].fullAddress;
    this.city = this.newClient.address[0].city;
    this.zip = this.newClient.address[0].zip;
    this.state = this.newClient.address[0].state;
    this.setDefaultTimeframeValue();
    this.editButtonPressed = this.editButtonPressed == true ? false : true;
    if (this.editButtonPressed) {
      this.selectedCategories=[];
      this.selectedSubcategories=[];
      this.updateClientCategory();
    }
  }

  /**
 	 "New Client" or "Joe Blow"
	*/
  getTitle() {
    if (this.clientId == null) {
      return 'New Client';
    }

    return `${this.newClient.firstName} ${this.newClient.lastName}`;
  }

  /**
 	 Show our checkmark completion animation. Auto closing.
	*/
  showCheckmark(next) {
    this.showCheck = true;
    setTimeout(
      function (self) {
        self.showCheck = false;

        if (next != null) {
          next(self);
        }
      },
      3000,
      this
    );
  }

  /**
 	 Just go back to clients list
	*/
  onCancelCreation() {
    this.gaService.sendCtaClickEvent({page_name:'new-client'},{cta_type: 'button', cta_location:'new_client_form', cta_description:'cancel'})
    if (this.isEditing()) {
      this.newClient.firstName = this.FirstName;
      this.newClient.lastName = this.LastName;
      this.newClient.picture = this.Image;
      this.newClient.email = this.email;
      this.newClient.phone = this.phone;
      this.newClient.address[0].fullAddress = this.address;
      this.newClient.address[0].city = this.city;
      this.newClient.address[0].zip = this.zip;
      this.newClient.address[0].state = this.state;
      this.toggleEdit();
      return;
    }

    this.returnToClients();
  }

  /**
 	 Route back to clients page
	*/
  returnToClients() {
    this.router.navigate(['/clients'], {
      queryParams: this.route.snapshot.queryParams,
    });
  }

  /**
 	 Return to tours and we can now remove the query params we were keeping track of
	*/
  returnToTours(newClientId) {
    // this.router.navigate(['/tours']);
    this.router.navigate([`/client/${newClientId}`]);
  }

  /**
 	 Previews uploaded pic
	*/
  onFileChanged(event) {
    if (!event.target.files[0]?.name?.match(/\.(jpg|jpeg|png)$/i) && event.target.files[0].type !== 'text/plain') {
			// const message = ` File format not supported.You can upload only jpg, jpeg, png extension file `;
			this.snackBar.openFromComponent(SnackbarMessageComponent,  {
				duration: 5000, // notification will automatically close after 5 seconds
				horizontalPosition: 'center', // position the notification at the right corner of the screen
				verticalPosition: 'top', // position the notification at the top of the screen
				panelClass: ['file-error-snackbar'] // add a custom CSS class to the notification
			});
			event.preventDefault();
			this.fileInput.nativeElement.value = '';
			return;
		}
    this.selectedFile = <File>event.target.files[0];
    let blob = URL.createObjectURL(this.selectedFile);

    //TODO: Secure this
    this.newClient.picture =
      this.domSanitizer.bypassSecurityTrustResourceUrl(blob);
  }

  onTimeframeSelection(event) {
    this.newClient.timeframe = event.value;
  }

  /**
 	 Fetch the list of possible timeframes from the api
	*/
  populateTimeframes() {
    // Populate timeframes
    this.clientService.getClientTimeframes().subscribe((timeframes) => {
      // Capture server error messages
      if (timeframes['errors'] != undefined) {
        this.error = checkForAuthError(timeframes['errors'], this.router);
        return;
      }

      // this.timeframes = timeframes['data']['getClientTimeframes'];
      this.timeframes = timeframes['data']['getClientTimeframes'].filter(
        (el) => {
          if (el.name.toLowerCase() == 'within a few months') {
            el['name'] = 'Within a month';
          }
          return el;
        }
      );
    });
  }

  /**
 	 Go fetch the client that was passed to us in our path
	*/
  fetchExistingClient() {
    // Populate timeframes
    this.clientService.fetchClients([this.clientId]).subscribe((clients) => {
      // Capture server error messages
      if (clients['errors'] != undefined) {
        this.error = checkForAuthError(clients['errors'], this.router);
        return;
      }

      let foundClient = clients['data']['clients'][0];

      // Client not found, go to new client
      if (foundClient == null) {
        this.router.navigate(['/new-client']);
        return;
      }

      foundClient.picture = foundClient.avatar?.url ?? this.imagePlaceholder;
      foundClient.timeframe = foundClient.clientTimeframe?.id;
      // foundClient.address = foundClient.address.fullAddress;
      foundClient.address = foundClient.address ? [foundClient.address] : [];

      this.newClient = foundClient;

      this.fetchPropertiesForExistingClient();
    });
  }
  updateClientCategory() {
    for (const poiCategoryItem of this.poiCategory) {
      const matchingClientCategory = this.newClient?.clientcategory.find(
        (clientCategory) => clientCategory.category.id === poiCategoryItem.id
      );

      if (matchingClientCategory) {
        poiCategoryItem.checked = true;
        this.selectedCategories.push(poiCategoryItem.id);

      
        if (matchingClientCategory.clientsubcategory.length > 0) {
          let i = 0;
          const subcategoryIds = [];
          for (const clientSubcategoryItem of matchingClientCategory.clientsubcategory) {
            const matchingSubcategory = poiCategoryItem.subcategory.findIndex(
              (subcategory) => {
                if (subcategory.id === clientSubcategoryItem?.subcategory.id) {
                  subcategoryIds.push(subcategory.id);
                  return clientSubcategoryItem;
                }
              }
            );
            if (matchingSubcategory!=-1) {
              poiCategoryItem.subcategory[matchingSubcategory].checked = true;
              // this.selectedSubcategories.push([matchingSubcategory.id]);
              // subcategoryIds.push(matchingSubcategory.id);
              // subcategoryNames.push(matchingSubcategory.name);
            }
            i++;
          }
          this.selectedSubcategories.push(subcategoryIds);
        }
      }
    }
  }

  fetchPropertiesForExistingClient() {
    this.clientService
      .fetchPropertiesForClient(this.clientId)
      .subscribe((clients) => {
        // Capture server error messages
        if (clients['errors'] != undefined) {
          this.error = checkForAuthError(clients['errors'], this.router);
          return;
        }
        this.newClient.properties = clients['data']['propertiesForClient'];
      });
  }

  addNewClientToTour(newClientId, tourId) {
    return new Promise<void>((resolve, reject) => {
      if (tourId == null) {
        resolve();
      }

      let clientIdList = [newClientId];
      this.clientService
        .addClientsToTour(clientIdList, tourId)
        .subscribe((showingTour) => {
          this.showSpinner = false;

          // Assume for alpha stage that an error means logged out
          if (showingTour['errors'] != null) {
            reject(showingTour['errors'][0].message);
            return;
          }

          resolve();
        });
    });
  }

  addPropertiesToTour(properties, tourId) {
    return new Promise<void>((resolve, reject) => {
      if (properties?.length < 1) {
        resolve();
      }

      let propertyIdList = properties.map((x) => x.id);
      this.toursService
        .addPropertiesToTour(propertyIdList, tourId)
        .subscribe((showingTour) => {
          // Assume for alpha stage that an error means logged out
          if (showingTour['errors'] != null) {
            reject(showingTour['errors'][0].message);
            return;
          }

          resolve();
        });
    });
  }

  onConfirmCreation() {
    this.error = null;
    this.gaService.sendCtaClickEvent({page_name:'new-client'},{cta_type:'button',cta_description:'create',cta_location:'new_client_form'})
    this.firstNameControl.setValidators([Validators.required]);
    this.lastNameControl.setValidators([Validators.required]);
    // this.phoneControl.setValidators([
    //   Validators.required,
    //   Validators.pattern('^[0-9]{10}$'),
    // ]); // Adding the phone number validation pattern
    this.phoneControl.setValidators([
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10)
    ]);
    // this.emailControl.setValidators([Validators.required, Validators.email]);
    this.emailControl.setValidators([Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]);
    this.timeframeControl.setValidators([Validators.required]);
    // this.categoryControl.setValidators([Validators.required])

    this.firstNameControl.updateValueAndValidity();
    this.lastNameControl.updateValueAndValidity();
    this.emailControl.updateValueAndValidity();
    this.phoneControl.updateValueAndValidity();
    this.timeframeControl.updateValueAndValidity();
    // this.categoryControl.updateValueAndValidity()

    this.firstNameControl.markAsTouched();
    this.lastNameControl.markAsTouched();
    this.phoneControl.markAllAsTouched();
    this.emailControl.markAsTouched();
    this.timeframeControl.markAsTouched();
    // this.categoryControl.markAsTouched()

    if (
      this.firstNameControl.invalid ||
      this.lastNameControl.invalid ||
      this.phoneControl.invalid ||
      this.emailControl.invalid ||
      this.timeframeControl.invalid
    ) {
      const formControls = [
				{ control: this.firstNameControl.invalid, message: 'First name with no symbols, digits and space is required' },
				{ control: this.lastNameControl.invalid, message: 'Last name with no symbols, digits and space is required' },
				{ control: this.phoneControl.invalid, message: 'Valid Phone number is required' },
				{ control: this.emailControl.invalid, message: 'Valid email is required' },
				{ control: this.timeframeControl.invalid, message: 'Please select a timeframe' }
			];
			
			formControls.forEach(({ control, message }) => {
				if (control) {
					this.gaService.sendErrorDisplayEvent({ page_name: "new-client", message, type: "form_validation" });
				}
			});
      return;
    }

    if (this.isEditing() && !this.isNewClient()) {
      if (this.selectedFile != null) {
        this.saveUpdateWithAvatar();
      } else {
        this.saveUpdate();
      }
      this.gaService.sendCtaClickEvent({page_name:'client'},{cta_type: 'button', cta_location:'client', cta_description:'save'})
      return;
    }

    this.confirmCreation();
  }

  saveUpdateWithAvatar() {
    this.error = null;

    this.showSpinner = true;

    let upload = this.photosService.uploadAvatar(
      this.selectedFile,
      this.newClient.id,
      null
    );

    // Upload the avatar if the user selected one, async. We don't care when this completes.
    if (upload != null) {
      upload.subscribe((avatar) => {
        // Capture server error messages
        if (avatar['errors'] != undefined) {
          this.error = avatar['errors'][0].message;
          this.showSpinner = false;

          return;
        }

        // const avatarUrl = avatar['data']['uploadAgentAvatar']['avatar']['url'];
        // this.newClient.picture = avatarUrl;

        this.saveUpdate();
      });
    }
  }

  saveUpdate() {
    // Reset error
    this.error = null;

    this.showSpinner = true;

    let clientAddress = this.newClient.address;
    let timeframe = this.newClient.timeframe;

    const selectedData = {
      catId: this.selectedCategories,
      subCatId: this.selectedSubcategories,
    };

    this.clientService.updateClient(this.newClient,selectedData).subscribe((clients) => {
      // Capture server error messages
      this.showSpinner = false;

      if (clients['errors'] != undefined) {
        this.error = clients['errors'][0].message;

        if (clientAddress) {
          this.newClient.address = clientAddress;
        }

        this.newClient.timeframe = timeframe;

        return;
      }

      const client = clients['data']['updateClient'];

      // client.address = client.address.fullAddress;
      client.address = client.address ? [client.address] : [];
      client.picture = client.avatar?.url ?? this.imagePlaceholder;
      client.timeframe = client.clientTimeframe?.id;
      this.newClient = client;

      this.showCheckmark((self) => {
        self.toggleEdit();
      });
    });
  }

  /**
 	 Create the new client 
	*/
  confirmCreation() {
    // Reset error
    this.error = null;

    // if (
    //   this.newClient.firstName == null ||
    //   this.newClient.lastName == null ||
    //   this.newClient.phone == null ||
    //   this.newClient.email == null ||
    //   this.newClient.address == null
    // ) {
    //   this.error = 'Please fill the all fileds. No fields can be empty';
    //   return;
    // }

    // Check for tour flow
    const tourFlowId = this.route.snapshot.queryParams['tour'];

    // Check for trailing properties
    const hasProperties = this.showTrailingProperties();
    let clientAddress = this.newClient.address;

    this.showSpinner = true;

    const selectedData = {
      catId: this.selectedCategories,
      subCatId: this.selectedSubcategories,
    };
    // Convert object to array of arrays
    const convertData=(inputData) =>{
      const convertedData = [];
      
      inputData.forEach(item => {
          // if (item.checked) {
              const poi = item.name;
              const sub_poi = [];
              
              item.subcategory.forEach(subItem => {
                  if (subItem.checked) {
                      sub_poi.push(subItem.name);
                  }
              });
              
              if (sub_poi.length > 0) {
                  convertedData.push({ poi, sub_poi:sub_poi.join(',') });
              }
          // }
      });
      
      return convertedData;
  }
    // const resultArray = Object.entries(convertedData).flatMap(([key, value]) => [
    //   [key],
    //   [value]
    // ]);
    // // Convert array of arrays to string
    // const resultString = resultArray.map(arr => arr.join(':')).join(' | ');
  //   console.log("object",this.selectedCategories,this.selectedCategoriesName,convertData(this.selectedCategoriesName).map(item => [
  //     `poi: ${item.poi}`,
  //     `sub_poi: ${item.sub_poi}"`
  // ]));
    this.clientService
      .addClient(this.newClient, selectedData)
      .subscribe((clients) => {
        // Capture server error messages
        if (clients['errors'] != undefined) {
          this.error = checkForAuthError(clients['errors'], this.router);
          this.showSpinner = false;

          if (clientAddress) {
            this.newClient.address = clientAddress;
          }
          this.gaService.sendErrorDisplayEvent({page_name:'new-client',message:this.error,type:this.error=='Unknown error.'?'unknown':'form_validation'});
          return;
        }

        const newClientId = clients['data']['createClient']['id'];
        let timeFrameName =this.timeframes[Number(this.newClient.timeframe)-1]['name'];
        // console.log("object",timeFrameName,this.poiCategory,this.selectedCategories,this.subcategoryControl);
        // const selectPoiCategory =this.poiCategory.filter(item=>this.selectedCategories.includes(item.id));
        // const dataPoiCategoryEvent=selectPoiCategory.map(e=>e['name'].trim());
        // const convertedData = {};
        // selectPoiCategory.forEach(item => {
        //     const name = item.name.trim();
        //     const subcategories = item.subcategory.map(sub => sub.name);
        //     convertedData[name] = subcategories.join(',');
        // });
        // const output = Object.entries(convertedData)
        // .map(([key, value]) => `${key}:${value}`)
        // .join(' | ');
        this.gaService.sendFormSubmitEvent({formType:'new-client',disType:'page'},{  
          lifestyle:this.selectedCategoriesName.length>0?convertData(this.selectedCategoriesName).map(item => [
            `poi: ${item.poi}`,
            `sub_poi: ${item.sub_poi}`
        ]):undefined,
          // poi:dataPoiCategoryEvent.length>0?dataPoiCategoryEvent.join(','):undefined,
          // lifestyle_subpoi:JSON.stringify(convertedData),
          // lifestyle_subpoi:selectPoiCategory.map(item => item.subcategory.map(sub => sub.name)),
          // sub_poi:outputString,
          timeframe:timeFrameName
          });
        // Prep the upload
        let upload = this.photosService.uploadAvatar(
          this.selectedFile,
          newClientId,
          null
        );

        // Upload the avatar if the user selected one, async. We don't care when this completes.
        if (upload != null) {
          upload.subscribe((avatar) => {
            // Capture server error messages
            if (avatar['errors'] != undefined) {
              this.error = checkForAuthError(avatar['errors'], this.router);
              this.gaService.sendErrorDisplayEvent({page_name:'new-client',message:this.error,type:"form_validation"})
              this.showSpinner = false;

              return;
            }
          });
        }

        this.showSpinner = false;

        this.showCheckmark((self) => {
          self.returnToTours(newClientId);
        });

        // Add client to trailing tour
        // this.addNewClientToTour(newClientId, tourFlowId)
        //   .then(() => {
        //     this.addPropertiesToTour(this.trailingProperties, tourFlowId)
        //       .then(() => {
        //         this.showSpinner = false;

        //         this.showCheckmark((self) => {
        //           self.returnToTours(newClientId);
        //         });
        //       })
        //       .catch((err) => {
        //         this.error = err;
        //         this.gaService.sendErrorDisplayEvent({page-name:'new_client',message:this.error,type:"form_validation"})
        //         this.showSpinner = false;
        //       });
        //   })
        //   .catch((err) => {
        //     this.error = err;
        //     this.gaService.sendErrorDisplayEvent({page_name:'new-client',message:this.error,type:"form_validation"})
        //     this.showSpinner = false;
        //   });
      });
  }

  favoriteTest() {
    this.clientService
      .favoriteProperty(1, this.newClient.id, 59)
      .subscribe((response) => {
        // Capture server error messages
        if (response['errors'] != undefined) {
          this.error = checkForAuthError(response['errors'], this.router);
          return;
        }
      });
  }

  /**
 	 Just for callback purposes. 
	*/
  onToggleFav(newStatus) {}

  /**
	 If any mlsId's are present as query parameters, this will indicate we have been fed them
	 by an external MLS source
	*/
  fetchTrailingProperties() {
    // Extract any passed list of mls id's
    let map = this.route.snapshot.queryParams;
    this.mlsList = map['mlsList']?.split(',');
    this.mlsName = map['mlsName'];

    // Guard for no items present
    if (this.mlsList == null || this.mlsName == null) {
      return;
    }

    this.propertyService
      .fetchPropertiesForMls(this.mlsList, this.mlsName)
      .subscribe((response) => {
        // Capture server error messages
        if (response['errors'] != undefined) {
          this.error = checkForAuthError(response['errors'], this.router);
          return;
        }

        this.trailingProperties = response['data']['propertiesFromMls'];
      });
  }
  formStartEvent(){
    this.gaService.sendFormStartEvent({formType:'new-client',disType:'page'});
  }
}

<div class="modal-background ">
  <div class="modal-content">
      <!-- <h2>Forgot Password</h2> -->
      <div class="logo-modal-position"><img src="./assets/logo-symbol.png" alt="Livepad Logo"><h5 class="modal-title">Forgot Password</h5></div>
      <label for="forgotPass">Email</label>
      <input type="text" [(ngModel)]="email" id="forgotPass" aria-labelledby="forgotPass" #emailInput="ngModel" required/>
      <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div> 
      <div class="button-wrapper">  
      <button type="submit" tabindex="0" (click)="submitEmail()" class="btn-primary">SUBMIT</button>
      <button type="reset" tabindex="0" mat-dialog-close class="btn-secondary">CANCEL</button>
    </div>
    </div>
</div>
import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { LoginService } from '../services/login.service';
import { User } from '../user';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { PasswordValidator } from '../validators/password.validator'
import { Agent } from '../agent'
import { Mls } from '../mls'
import { MlsService } from '../services/mls.service'
import { SpinnerComponent } from '../spinner/spinner.component'
// import { CheckmarkComponent } from '../checkmark/checkmark.component'
import { MatSnackBar } from '@angular/material/snack-bar'
// import { CdkTrapFocus } from '@angular/cdk/a11y';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordModalComponent } from '../forgot-password-modal/forgot-password-modal.component';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
	/**
 	 Assume the user is an agent
	*/
	isAgent = true

	/**
 	 Show / hide the registration divs
	*/
	showRegistration: boolean = false

	/**
 	 Storing the user object
	*/
	user:User

	/**
 	 Email control
	*/
	emailControl = new UntypedFormControl('')

	/**
 	 Name control, no symbols
	*/
	nameControl = new UntypedFormControl('')

	/**
 	 First name control
	*/
	firstNameControl = new UntypedFormControl('')

	/**
 	 Last name control
	*/
	lastNameControl = new UntypedFormControl('')

	/**
 	 Phone control
	*/
	phoneControl = new UntypedFormControl('')

	/**
 	 Password control
	*/
	passwordControl = new UntypedFormControl('')

	/**
 	Mls control
	*/
	mlsControl = new UntypedFormControl('')

	mlsList: Mls[]

	/**
 	 Space to contain error message
	*/
	error: string = null

	/**
 	 Value for when the spinner should be shown
	*/
	showSpinner = false

	/**
 	 Show the done checkmark
	*/
	showDone = false
	fieldTextType: boolean = false;

	constructor( 
		private loginService: LoginService, 
		private snackBar: MatSnackBar,
		private router:Router,
		private mlsService: MlsService,
		private changeDetector: ChangeDetectorRef,
		// private modalService: NgbModal,
		private dialogRef: MatDialog,
		private gaService: GoogleAnalyticsService ) {

			this.user = loginService.getUser()
			
			this.gaService.sendPageLoadEvent('login',{page_name:'login'});	  
	}

	@ViewChild('passwordPolicyDialog') passwordPolicyDialogTemplate: TemplateRef<any>;
    ngOnInit () { 


    }

	@ViewChild("yourMls") yourMls: ElementRef;
	toggleFieldTextType() {
		this.fieldTextType = !this.fieldTextType;
	}
	onKey(event: KeyboardEvent) {
		event.preventDefault();
		if (event.key === "Tab") {
			this.yourMls.nativeElement.value = "yourMls";
			this.changeDetector.detectChanges();
			this.yourMls.nativeElement.focus();
		}
	
	}

    fetchMlsList() {

    	this.error = null

		this.mlsService
			.fetchMlsList()
			.subscribe( (mlsList) => {

	  			// Capture server error messages
	  			if ( mlsList["errors"] != undefined ) {
	  				this.error = mlsList["errors"][0].message
	  				return
	  			}

	  			this.mlsList = mlsList['data']['contractedMlsList']

			})
    }

	openForgotPasswordModal(): void {
		const dialogRef = this.dialogRef.open(ForgotPasswordModalComponent, {
		  panelClass: 'app-create-user-dialog',
		  enterAnimationDuration: '300ms',
		  exitAnimationDuration: '150ms',
		  ariaLabel: 'forgot password model',
		//   data:{option:'editOrg',orgData}
		});
		dialogRef.afterClosed().subscribe(
		  data => {
			// if(data?.data?.submitted){
			// }else{
			//   return
			// }
		  }
		); 
	// 	this.modalRef = this.modalService.open(ForgotPasswordModalComponent, {
	// 	  centered: true,
	// 	  backdrop: 'static', // To prevent closing the modal by clicking outside
	// 	  keyboard: false, // To prevent closing the modal with the Esc key
	// 	})
	// 	this.modalRef.componentInstance.modalData = { }
	// 	// Listen for the modal close event to handle any actions if needed
	// 	this.modalRef.componentInstance.close.subscribe((res) => {
	// 	  // Optionally, you can perform any actions here after the modal is closed.
	// 	this.modalRef.result.then(
	// 		(result) => {
	// 		  console.log('Modal closed with result:', result);
	// 		},
	// 		(reason) => {
	// 		  console.log('Modal dismissed with reason:', reason);
	// 		}
	// 	  );
	// 	  if (this.modalRef) {
	// 		this.modalRef.close();
	// 		this.modalRef.componentInstance.close.unsubscribe();
	// 		// this.modalRef = null;
	// 	  }
	//   })
	}
	
	userFromControls() {

		this.user.name = this.nameControl.value,
		this.user.email= this.emailControl.value,
		this.user.password = this.passwordControl.value
		this.loginService.user = this.user
	}

	onLogin () {

		this.error = null

		// this.emailControl.setValidators([Validators.required, Validators.email])
		this.emailControl.setValidators([Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]);
		this.passwordControl.setValidators(Validators.required)

		this.emailControl.updateValueAndValidity()
		this.passwordControl.updateValueAndValidity()

		this.emailControl.markAsTouched()
		this.passwordControl.markAsTouched()

		if ( this.emailControl.invalid || this.passwordControl.invalid ) {
			let invalidError:{page_name:string,message:string,type:string};
			if(this.emailControl.invalid && !this.passwordControl.invalid){
				invalidError={page_name:"login",message:'Valid email required',type:'form_validation'};
			}
			if(this.passwordControl.invalid && !this.emailControl.invalid){
				invalidError={page_name:"login",message:'Valid password required',type:'form_validation'}
			}
			if(this.emailControl.invalid && this.passwordControl.invalid){
                invalidError = {page_name:'login',message:'Please enter valid email and password',type:'form_validation'}
            }
			this.gaService.sendErrorDisplayEvent(invalidError);
			return;
		}
		this.gaService.sendFormSubmitEvent({formType:'login',disType:'page'});
		this.userFromControls()

		this.loginService
		    .login( () => { 
		    	delete this.user["password"]
				const targetUrl = this.loginService.getTargetUrl();
		    	if (targetUrl) {
					this.router.navigateByUrl(targetUrl);
					this.loginService.clearTargetUrl(); // Clear the saved URL
				  } else {
					const passwordErrors = PasswordValidator.validate(this.passwordControl);
					if (passwordErrors) {
						this.openPasswordPolicyDialog();
					} else {
						// If password is valid, redirect to dashboard
						this.router.navigate(['dashboard']);
					}
				  }
		    }, (err) => {
				console.log("err",err);

				if(err === 'Error: reset'){
					delete this.user["password"]
					this.router.navigate(['change-password']);
					localStorage.setItem('resetEmail', this.user["email"]);
				}
				else{
					delete this.user["password"]
					this.error = err
					this.gaService.sendErrorDisplayEvent({page_name:"login",message:err,type:'form_validation'});
				}
		    })

	}

	showCheckmark(next) {

	    this.showDone = true
	    setTimeout(() => {

	    	this.showDone = false

	    	if ( next != null ) {

	    		// Pass "self" context
	    		next(this)
	    	}

	    }, 3000)
	}

	beginRegistration() {
		this.error = null
		// this.showRegistration = true
		this.router.navigate(['register'])
		this.nameControl.clearValidators()
		this.firstNameControl.clearValidators()
		this.lastNameControl.clearValidators()
		this.passwordControl.clearValidators()
		this.emailControl.clearValidators()
		this.passwordControl.clearValidators()
		this.emailControl.reset()
		this.passwordControl.reset()
	}

	onEnter() {
		this.onLogin()
	}
	formStartEvent(){
		this.gaService.sendFormStartEvent({formType:'login',disType:'page'});
	}
	pageCtaClick(event:Event){
		event.stopPropagation();
		const element = (event?.target as HTMLElement);
		const roleValue = element.getAttribute('role');
		const elementName =element?.innerText.includes(' ')?element?.innerText?.replace(' ','_').toLowerCase():element?.innerText?.toLowerCase()
		this.gaService.sendCtaClickEvent({page_name:'login'},{cta_type:roleValue,cta_location:'login_form',cta_description:elementName})
	}

	openPasswordPolicyDialog(): void {
		this.loginService.logout();
		const dialogRef = this.dialogRef.open(this.passwordPolicyDialogTemplate, {
		  width: '400px',
		  disableClose: true,
		  data: {} // You can pass any data to the dialog if needed
		});
	  
		dialogRef.afterClosed().subscribe(result => {
		  this.router.navigate(['change-password']);
		  localStorage.setItem('resetEmail', this.user["email"]);
		});
	}

	closePasswordPolicyDialog(): void {
		this.dialogRef.closeAll(); // Close all open dialogs
	}
}

import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
// import { addCommasToPrice } from '@app/utils';
declare global {
  interface Window {
    dataLayer: any[];
  }
}
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  user:any;
  userRole:string[]=["superadmin","admin","agent"]
  userRoleId:string;
  constructor(private loginService: LoginService) {
    this.user = this.loginService.getUser();
    const userRole =this.loginService.user.orguserdetails[0]?.roleId;
    this.userRoleId = userRole?this.userRole[userRole-1]:undefined;
   }
  /***
   * @deviceType //check users device type as mobile|tablet|desktop
   */
  deviceType(){
      const ua =window.navigator.userAgent;
      let deviceType = '';
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        deviceType = "tablet";
      }
      else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        deviceType = "mobile";
      }else{
        deviceType = "desktop";
      }
      return deviceType;
  };
  /***
   * @pushEvent //lt_page_load using window.dataLayer
   pushEvent(event: string, eventData: any): void {
   window.dataLayer = window.dataLayer || [];
   eventData['user_source'] = this.deviceType();
   window.dataLayer.push({
      event,
      ...eventData
    });
   }
   */

  /***
   * @commonEvent //lt_page_load using window.dataLayer
   */
  sendEvent(eventName: string, eventData?: any): void {
    gtag('event', eventName, eventData);
  }
  /***
   * using gtag
   * @sendPageLoadEvent //lp_page_load for property-page and login-page
   * @sendFormStartEvent //lp_form_start
   * @sendFormSubmitEvent //lp_form_submit once submit the form
   * @sendCtaClickEvent //lp_cta_click
   * @sendErrorDisplayEvent //lp_error_display
   * @sendListInteractionEvent //lp_list_interaction
   * 
   */
  sendPageLoadEvent(pageFrom:string,data:any): void {
    let eventData:any={}
    const userId = this.user['id']?this.user['id']:this.loginService.user.id;
    if(this.loginService?.user?.orguserdetails !=undefined){
        const userRole =this.loginService?.user?.orguserdetails[0]?.roleId;
        this.userRoleId = userRole?this.userRole[userRole-1]:undefined;
    }
    if(pageFrom=='property'){
      const { listingKey, propertyFeatures ,address} = data || {};
      const { featureData } = propertyFeatures || {};
      eventData= {
        page_name: `property-${listingKey}`,
        user_source: this.deviceType(),
        user_id:userId,
        user_role:`${this.userRoleId}`,
        property_id:`${listingKey}`,
        property_city:address?.city ??'', 
        property_state:address?.state ??'',
        property_type:featureData?.PropertyType ??'',
        property_furnish:featureData?.Furnished ?? "N/A",
        property_dom:featureData?.DaysOnMarket ??'',
        property_view:featureData?.View??'' ,//&& (featureData?.View !=='undefined'||featureData?.View !=='undefine')?featureData?.View:'undefined',
        property_price:featureData?.ListPrice ??'',
        property_bedroom:featureData?.BedroomsTotal ??'N/A', 
        property_bathroom:featureData?.BathroomsTotalInteger ??'N/A',
        property_sqft:featureData?.LivingArea ??'N/A',
        property_lot_size:featureData?.LotSizeAcres ??'N/A',
      }
    }else if(pageFrom=='login'){
      eventData={...data,
        user_source: this.deviceType(),
        // user_id:undefined,
      }
    }
    this.sendEvent('lp_page_load',eventData)
  }
  sendFormStartEvent(data:{formType:string,disType:string}): void {
    this.sendEvent( 'lp_form_start', {
      form_type:data?.formType,
      form_display_type:data?.disType
    });
  }
  sendFormSubmitEvent(data:{formType:string,disType:string},value?:any): void {
  //   let submittedValue={}
  //   if(value){
  //     submittedValue={
  //     form_type:data?.formType,
  //     form_display_type:data?.disType,
  //     ...value
  //     }
  //   }else{
  //     submittedValue={
  //       form_type:data?.formType,
  //       form_display_type:data?.disType}
  //   }
  //  this.sendEvent( 'lp_form_submit',submittedValue );

   this.sendEvent('lp_form_submit',{
    form_type:data?.formType,
    form_display_type:data?.disType,
    ...value
    })
  }
  sendCtaClickEvent(data:any,value?:any): void {
    let ctaData={};
    if(data.page_name == 'login' || data.page_name =='register' || data.page_name.includes('client-complete'||'client_complete')){
      ctaData={
        page_name: data.page_name,
        user_source: this.deviceType(),
        ...value
      }
    }else{
      const userId = this.user['id']?this.user['id']:this.loginService.user.id;
      if(this.loginService?.user?.orguserdetails !=undefined){
          const userRole =this.loginService?.user?.orguserdetails[0]?.roleId;
          this.userRoleId = userRole?this.userRole[userRole-1]:undefined;
      }
      ctaData={
        page_name: data.page_name,
        user_source: this.deviceType(),
        user_id:userId,
        user_role:`${this.userRoleId}`,
        ...value
      }
    }
    console.log("object--cta-click",ctaData);
   this.sendEvent( 'lp_cta_click', ctaData);
  }
  sendErrorDisplayEvent(data:{page_name:string,message:string,type:string}): void {
   this.sendEvent( 'lp_error_display', {
      page_name: data.page_name,
      message:data.message,
      type:data.type,//“no_results_found”
    });
  }
  // sendListInteractionEvent(data:any): void {
  //  this.sendEvent( 'lp_list_interaction', {
  //     page_name: ``,
  //     user_source: this.deviceType(),
  //       user_id:this.user['id'],
  //       user_role:`${this.userRoleId}`
  //   });
  // }
}

<div class="content-container">
	<div class="dashboard">
		<!-- <div class="title">
			<img src=" assets/dashboard-icon.svg" alt="Dashboard Icon" width="40px" tabindex="0">
			<h1 tabindex="0">DASHBOARD</h1>
		</div> -->
		<div class="title-bar" [ngClass]="(user?.name.length > 15)?'titlelengthexceeds':''">
		  <h1>Dashboard</h1>
		  <div class="welcome-name">Welcome <span *ngIf="user">{{capitalizeName(user?.name)}}</span></div>
		</div>
		<div class="content-container">
		<div class="cards-section" >
			<div class="centertoleft cards" (click)="gotoTour();pageCtaClick($event,'tours')" role="icon" role-describe="TOURS">
				<!-- <img src="assets/form_background.svg" alt="Image" class="bg-img" tabindex="0">	 -->
				<div class="child-div">							
				<div class="img-align">
					<img src="assets/tours-icon.png" class="hvr-push" alt="Tours Icon" tabindex="0" height="90">
				</div>
					<p class="mt-3 mobile-margin" tabindex="0">Tours</p>	
					</div>			
			</div>

			<div (click)="gotoClient();pageCtaClick($event,'clients')" role="icon" class="show-first mid-div cards" role-describe="CLIENTS">
				<!-- <img src="assets/form_background.svg" alt="Image" class="bg-img" tabindex="0"> -->
				<div class="child-div">	
				<div class="img-align">	
					<img src="assets/clients-icon.png" alt="Clients Icon" class="hvr-push" tabindex="0" height="90">
				</div>
					<p class="mt-3 mobile-margin" tabindex="0">Clients</p>
				</div>
			</div>

			<div class="centertoright cards" (click)="gotoProperties();pageCtaClick($event,'properties')" role="icon" role-describe="PROPERTIES">
				<!-- <img src="assets/form_background.svg" alt="Image" class="bg-img" tabindex="0"> -->
				<div class="child-div">	
				<div class="img-align">	
					<img src="assets/properties-icon.png" alt="Properties Icon" class="hvr-push" tabindex="0" height="90">
				</div>
					<p class="mt-3 mobile-margin" tabindex="0">Properties</p>
				</div>
			</div>
			<div class="centertoright cards"  *ngIf="isAdmin || isSuperAdmin" (click)="adminpage();pageCtaClick($event,'admin')" role="icon" role-describe="ADMIN">
				<!-- <img src="assets/form_background.svg" alt="Image" class="bg-img" tabindex="0"> -->
				<div class="child-div">
				<div class="img-align">        
					<img src="assets/admin-icon.png" alt="Admin Icon" class="hvr-push" tabindex="0" height="90">
				</div>
					<p class="mt-3 mobile-margin" tabindex="0">Admin</p>
				</div>
			</div>
			<div class="centertoright cards"   (click)="gotoMyDoc();pageCtaClick($event,'my-documents')" role="icon" role-describe="DOCUMENTS">
				<!-- <img src="assets/form_background.svg" alt="Image" class="bg-img" tabindex="0"> -->
				<div class="child-div">
				<div class="img-align">        
					<img src="assets/my-document-icon.svg" alt="Admin Icon" class="hvr-push" width="30%" tabindex="0" height="90">
				</div>
					<p class="mt-3 mobile-margin" tabindex="0">Documents</p>
				</div>
			</div>
			
		</div>
	</div>
		<!-- <div class="welcome" tabindex="0">
			<label class="horizontalline"><b class="adafix">.</b></label>
			Welcome
			<label class="horizontalline"><b class="adafix">.</b></label>
		</div>
		<div class="line"></div> -->
	</div>
</div>
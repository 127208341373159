<div style="position: relative;" class="content-container signup-card">
	<!-- <div class="welcome" tabindex="0">
		<label class="horizontalline"><b class="adafix">.</b></label>
		{{"REGISTER"}}
		<label class="horizontalline"><b class="adafix">.</b></label>
	</div> -->
	<!-- <div class="title-bar">
		  <h1></h1>
		</div> -->
		<div class="logo-modal-position">
                    <img src="./assets/logo-symbol.png" alt="Livepad logo-symbol" tabindex="0">
                    <h5 class="modal-title" tabindex="0">{{"Register"}}</h5>
                   </div>
	<div *ngIf="isAgent" class="form-signup-Image">
	<!-- <div class="triangle-signup-bg">
		<img src="assets/livepad-logo-black.svg" alt="Livepad Logo" class="livpadLogo1" tabindex="0">
	</div> -->	
	<div class="signup-container">
		<div class="handleForm">
			<!-- <h1 class="signup-title" tabindex="0">Sign Up</h1>
			<hr> -->
			<div class="row">
				<div class="col-md-4 Colpad">
					<div class="form-group groupMargin">
						<label class="nameLabel" tabindex="0" for="userName">Username <span style="color: var(--lp-background-red);">*</span></label>
						<input type="text" [formControl]="nameControl" id="userName" aria-labelledby="userName" class="inputTag form-control" style="background:transparent;" tabindex="0" Username autocomplete="off" (keyup.enter)="onEnter()" (focus)="formStartEvent()"/>
						<div class="error-message" *ngIf="nameControl.invalid">A user name with no symbols is required</div>
					</div>
				</div>
				<div class="col-md-4 Colpad">
					<div class="form-group groupMargin">
						<label class="nameLabel" tabindex="0" for="firstName">First Name <span style="color : var(--lp-background-red);">*</span></label>
						<input type="text" [formControl]="firstNameControl" id="firstName" aria-labelledby="firstName" class="inputTag form-control" tabindex="0"	style="background:transparent;" FirstName autocomplete="off" (keyup.enter)="onEnter()"/>
						<div class="error-message" *ngIf="firstNameControl.invalid">First name with no symbols, digits and space is required</div>	
					</div>
				</div>
				<div class="col-md-4 Colpad">
					<div class="form-group">
						<label class="nameLabel" tabindex="0" for="lastName">Last Name <span style="color : var(--lp-background-red);">*</span></label>
						<input type="text" [formControl]="lastNameControl" id="lastName" aria-labelledby="lastName" class="inputTag form-control"
								style="background:transparent;" tabindex="0" LastName autocomplete="off" (keyup.enter)="onEnter()"/>
						<div class="error-message" *ngIf="lastNameControl.invalid">Last name with no symbols, digits and space is required</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4 Colpad">
					<div class="form-group">
					<label class="nameLabel" tabindex="0" for="phone">Phone <span style="color : var(--lp-background-red);">*</span></label>
					<input #input type="tel" [formControl]="phoneControl" tabindex="0" id="phone" aria-labelledby="phone" class="inputTag form-control" style="background:transparent;" autocomplete="off" NumberOnly (keyup.enter)="onEnter()"  (keydown.Tab)="onKey($event);"/>
					<div class="error-message" *ngIf="phoneControl.invalid">Valid Phone Number is required</div>
				</div>
				</div>
				<div class="col-md-5 Colpad">
					<div class="form-group">
					<label #email class="nameLabel" tabindex="0" for="signin-email">Email <span style="color : var(--lp-background-red);">*</span></label>
					<input type="text" [formControl]="emailControl" tabindex="0" autocomplete="off" class="inputTag form-control" id="signin-email" aria-labelledby="signin-email" style="background:transparent ;" (keyup.enter)="onEnter()"/>
					<div class="error-message" *ngIf="emailControl.invalid">Valid email is required</div>
				</div>
				</div>
			</div>

			<div class="row cusNmargin">
				<div class="col-md-6 Colpad ">
					<mat-label htmlFor="yourMls" #yourMls class="nameLabel" tabindex="0">Select Your Organization <span style="color : var(--lp-background-red);filter:contrast(1)">*</span></mat-label>
						<mat-select class="inputTag form-control" tabindex="0" id="yourMls" aria-labelledby="yourMls" [formControl]="orgControl" style="background:transparent;" (keyup.enter)="onEnter()" autocomplete="off">
							<mat-option *ngFor="let org of orgList" [value]="org"  class=""
								style="background:transparent;">
								{{org.name}}
							</mat-option>
						</mat-select>
					<!-- <div class="custom-select-arrow position-relative">
						<select class="inputTag form-control" tabindex="0" id="yourMls" aria-labelledby="yourMls" [formControl]="orgControl" style="background:transparent;" (keyup.enter)="onEnter()">
							<option *ngFor="let org of orgList" [value]="org" autocomplete="off" class=""
							style="background:transparent;">
							{{org.name}}
							</option>
						</select>
					</div> -->
					<div class="error-message" *ngIf="orgControl.invalid">Organization is required</div>
				</div>
			</div>

			<!-- <div style="text-align: center; font-size: 20px; margin-top: 2%; font-family: var(--lp-font-family);">
				<span style="color: black;">
					If you cannot find your organization in the above list, please 
					<span (click)="orgControl.value ? ($event.preventDefault() && $event.stopPropagation()) : onLinkClick()" [style.color]="orgControl.value ? 'grey' : '#0C4675'" style="cursor: pointer; font-weight: bold;">
						click here
					</span>	
				</span>
			</div> -->

			<div class="">
				<div class="signBtn">
					<button type="submit" class="btn btnColor form-control" (click)="onRegister();pageCtaClick($event)" role="button" tabindex="0"> REQUEST SIGN UP
					</button>
					<button type="reset" class="btn  signup form-control" (click)="cancelRegistration();pageCtaClick($event)" role="button" tabindex="0">
						CANCEL
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="error-message" *ngIf="error != null">{{error}}</div>
</div>
</div>
<app-spinner [show]="showSpinner"></app-spinner>
<app-checkmark *ngIf="showDone"></app-checkmark>
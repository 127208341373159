import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { LoginService } from '../services/login.service';
import { User,Organization } from '../user';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { PasswordValidator } from '../validators/password.validator'
import { Agent } from '../agent'
import { Mls } from '../mls'
import { MlsService } from '../services/mls.service'
import { SpinnerComponent } from '../spinner/spinner.component'
import { CheckmarkComponent } from '../checkmark/checkmark.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { AdminService } from '@app/services/admin.service';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  /**
 	 Assume the user is an agent
	*/
	isAgent = true

	/**
 	 Show / hide the registration divs
	*/
	showRegistration: boolean = false

	/**
 	 Storing the user object
	*/
	user:User

	/**
 	 Email control
	*/
	emailControl = new UntypedFormControl('')

	/**
 	 Name control, no symbols
	*/
	nameControl = new UntypedFormControl('')

	/**
 	 First name control
	*/
	firstNameControl = new UntypedFormControl('')

	/**
 	 Last name control
	*/
	lastNameControl = new UntypedFormControl('')

	/**
 	 Phone control
	*/
	phoneControl = new UntypedFormControl('')

	/**
 	 Password control
	*/
	// passwordControl = new UntypedFormControl('')

	/**
 	Mls control
	*/
	mlsControl = new UntypedFormControl('')

	orgControl = new UntypedFormControl('')

	mlsList: Mls[]

	orgList: Organization[];

	/**
 	 Space to contain error message
	*/
	error: string = null

	/**
 	 Value for when the spinner should be shown
	*/
	showSpinner = false

	/**
 	 Show the done checkmark
	*/
	showDone = false


	constructor( 
		private loginService: LoginService, 
		private snackBar: MatSnackBar,
		private router:Router,
		private mlsService: MlsService,
		private adminService: AdminService,
		private changeDetector: ChangeDetectorRef,
		private gaService: GoogleAnalyticsService ) {

			this.user = loginService.getUser()

			// this.fetchMlsList();
			this.fetchOrgList();
	}

    ngOnInit () { 


    }

	@ViewChild("email") email: ElementRef;

	onKey(event: KeyboardEvent) {
		event.preventDefault();
		if (event.key === "Tab") {
			this.email.nativeElement.value = "email";
			this.changeDetector.detectChanges();
			this.email.nativeElement.focus();
		}
	
	}

	// @ViewChild("yourMls") yourMls: ElementRef;

	// onKey(event: KeyboardEvent) {
	// 	event.preventDefault();
	// 	if (event.key === "Tab") {
	// 		this.yourMls.nativeElement.value = "yourMls";
	// 		this.changeDetector.detectChanges();
	// 		this.yourMls.nativeElement.focus();
	// 	}
	
	// }

    fetchMlsList() {

    	this.error = null

		this.mlsService
			.fetchMlsList()
			.subscribe( (mlsList) => {

	  			// Capture server error messages
	  			if ( mlsList["errors"] != undefined ) {
	  				this.error = mlsList["errors"][0].message
	  				return
	  			}

	  			this.mlsList = mlsList['data']['contractedMlsList']

			})
    }

	fetchOrgList() {
		this.error = null;
		this.loginService.orgList().subscribe((orgList) => {
		  // Capture server error messages
		  if (orgList['errors'] != undefined) {
			this.error = orgList['errors'][0].message;
			return;
		  }
		  this.orgList = orgList['data']['getOrgsList'];
		//   this.orgList = this.orgList.filter((organization) => organization.status === 1);
		//   this.orgList.forEach((organization) => {
		// 	if (organization.id == 1) {
		// 	  organization.name = "None of the Above";
		// 	}
		//   });
			const noneOfTheAbove = orgList['data']['getOrgsList'].find(organization => organization.id == 1);
			this.orgList = orgList['data']['getOrgsList'].filter(
			(organization) => organization.status === 1 && organization.id != 1
			).sort((a, b) => a.name.localeCompare(b.name));

			if (noneOfTheAbove) {
				noneOfTheAbove.name = "None of the Above";
				this.orgList.push(noneOfTheAbove);
			}
		});
	}
	

	userFromControls() {

		this.user.name = this.nameControl.value,
		this.user.email= this.emailControl.value,
		// this.user.password = this.passwordControl.value
		this.loginService.user = this.user
	}

	onLinkClick () {
		this.error = null

		this.nameControl.setValidators( [Validators.required] )
		this.firstNameControl.setValidators( [Validators.required] )
		this.lastNameControl.setValidators( [Validators.required] )
		this.phoneControl.setValidators([Validators.required, Validators.pattern('^[0-9]{10}$')]);
		// this.emailControl.setValidators([Validators.required, Validators.email])
		this.emailControl.setValidators([Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]);

		this.nameControl.updateValueAndValidity()
		this.firstNameControl.updateValueAndValidity()
		this.lastNameControl.updateValueAndValidity()
		this.emailControl.updateValueAndValidity()
		this.phoneControl.updateValueAndValidity()

		this.nameControl.markAsTouched()
		this.firstNameControl.markAsTouched()
		this.lastNameControl.markAsTouched()
		this.phoneControl.markAllAsTouched()
		this.emailControl.markAsTouched()


		if ( this.emailControl.invalid  || this.nameControl.invalid || this.firstNameControl.invalid || this.lastNameControl.invalid || this.phoneControl.invalid) {
			return
		}

		this.userFromControls()

		let agent: Agent = null

		if ( this.isAgent ) {
			agent = {
				id: null,
				userName: this.nameControl.value,
				firstName: this.firstNameControl.value,
				lastName: this.lastNameControl.value,
				fullName: `${this.firstNameControl.value} ${this.lastNameControl.value}`,
				email: this.emailControl.value,
				phone: this.phoneControl.value,
				mls: null,
				org:null,
				address: null,
				picture: null,
				link: null,
				license: null
			}
		}
		
		this.showSpinner = true
		this.error = null
		this.loginService
		    .addLeadRequest(agent, (res:any) => { 
				if (res['errors'] != undefined) {
					this.showSpinner = false
					this.snackBar.open(res['errors'][0].message, 'Close', {
						duration: 5000, // notification will automatically close after 5 seconds
						horizontalPosition: 'center', // position the notification at the right corner of the screen
						verticalPosition: 'top' // position the notification at the top of the screen
						});
					return;
				}
				this.showSpinner = false
				this.showRegistration = false
				this.snackBar.open('Request submitted successfully, LivePad team will contact you shortly!', 'Close', {
				duration: 5000, // notification will automatically close after 5 seconds
				horizontalPosition: 'center', // position the notification at the right corner of the screen
				verticalPosition: 'top' // position the notification at the top of the screen
				});
				this.nameControl.reset();
				this.firstNameControl.reset();
				this.lastNameControl.reset();
				this.emailControl.reset();
				this.phoneControl.reset();
				this.showRegistration = false;
				this.router.navigate(['login'])

		    }, (err) => {

		    	this.showSpinner = false
		    	this.error = err
		    })

	}

	/**
 	 Submits user registration form if valid
 	 TODO: Holy shit please refactor later
	*/
	onRegister () {

		this.error = null

		this.nameControl.setValidators( [Validators.required] )
		this.firstNameControl.setValidators( [Validators.required] )
		this.lastNameControl.setValidators( [Validators.required] )
		this.orgControl.setValidators([Validators.required])
		// this.phoneControl.setValidators([Validators.required,]);	
		this.phoneControl.setValidators([Validators.required, Validators.pattern('^[0-9]{10}$')]);
		// this.emailControl.setValidators([Validators.required, Validators.email])
		this.emailControl.setValidators([Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]);
		// this.passwordControl.setValidators([Validators.required, PasswordValidator.strong] ) //, Validators.min(6), Validators.max(14)

		this.nameControl.updateValueAndValidity()
		this.firstNameControl.updateValueAndValidity()
		this.lastNameControl.updateValueAndValidity()
		this.orgControl.updateValueAndValidity(),
		this.emailControl.updateValueAndValidity()
		this.phoneControl.updateValueAndValidity()
		// this.passwordControl.updateValueAndValidity()

		this.nameControl.markAsTouched()
		this.firstNameControl.markAsTouched()
		this.lastNameControl.markAsTouched()
		this.orgControl.markAsTouched()
		this.phoneControl.markAllAsTouched()
		this.emailControl.markAsTouched()
		// this.passwordControl.markAsTouched()


		if ( this.emailControl.invalid  || this.nameControl.invalid || this.firstNameControl.invalid || this.lastNameControl.invalid || this.phoneControl.invalid || this.orgControl.invalid) {
			const formControls = [
				{ control: this.firstNameControl.invalid, message: 'First name with no symbols, digits and space is required' },
				{ control: this.lastNameControl.invalid, message: 'Last name with no symbols, digits and space is required' },
				{ control: this.phoneControl.invalid, message: 'Valid Phone Number is required' },
				{ control: this.emailControl.invalid, message: 'Valid Email is required' },
				{ control: this.nameControl.invalid, message: 'A user name with no symbols is required' },
				{ control: this.orgControl.invalid, message: 'Organization is required' }
			];
			
			formControls.forEach(({ control, message }) => {
				if (control) {
					this.gaService.sendErrorDisplayEvent({ page_name: "register", message, type: "form_validation" });
				}
			});
			return
		}
		this.userFromControls()
		this.gaService.sendFormStartEvent({formType:'sign_up',disType:'page'});

		let agent: Agent = null

		const orgMlsIds = this.orgControl.value.orgmls.map(item => item.contractedMls.id);

		if(this.orgControl.value.id == 1){
			if ( this.isAgent ) {
				agent = {
					id: null,
					userName: this.nameControl.value,
					firstName: this.firstNameControl.value,
					lastName: this.lastNameControl.value,
					fullName: `${this.firstNameControl.value} ${this.lastNameControl.value}`,
					email: this.emailControl.value,
					phone: this.phoneControl.value,
					mls: null,
					org:null,
					address: null,
					picture: null,
					link: null,
					license: null
				}
			}
			
			this.showSpinner = true
			this.error = null
			this.loginService
				.addLeadRequest(agent, (res:any) => { 
					if (res['errors'] != undefined) {
						this.showSpinner = false
						this.snackBar.open(res['errors'][0].message, 'Close', {
							duration: 5000, // notification will automatically close after 5 seconds
							horizontalPosition: 'center', // position the notification at the right corner of the screen
							verticalPosition: 'top' // position the notification at the top of the screen
							});
							this.gaService.sendErrorDisplayEvent({ page_name: "register",message:res['errors'][0].message, type: "form_validation" });
						return;
					}
					this.showSpinner = false
					this.showRegistration = false
					this.snackBar.open('Request submitted successfully, LivePad team will contact you shortly!', 'Close', {
					duration: 5000, // notification will automatically close after 5 seconds
					horizontalPosition: 'center', // position the notification at the right corner of the screen
					verticalPosition: 'top' // position the notification at the top of the screen
					});
					this.nameControl.reset();
					this.firstNameControl.reset();
					this.lastNameControl.reset();
					this.emailControl.reset();
					this.phoneControl.reset();
					this.showRegistration = false;
					this.router.navigate(['login'])
	
				}, (err) => {
	
					this.showSpinner = false
					this.error = err
				})
		}
		else {
			if ( this.isAgent ) {
				agent = {
					id: null,
					userName: null,
					firstName: this.firstNameControl.value,
					lastName: this.lastNameControl.value,
					fullName: `${this.firstNameControl.value} ${this.lastNameControl.value}`,
					email: this.emailControl.value,
					phone: this.phoneControl.value,
					mls: orgMlsIds,
					org: this.orgControl.value.id,
					address: null,
					picture: null,
					link: null,
					license: null
				}
			}
			
			this.showSpinner = true
			this.error = null
			console.log("agent details is",agent);
			this.gaService.sendFormSubmitEvent({formType:'sign_up',disType:'page'},{mls_organization:this.orgControl.value.name.trim().toLowerCase()});
			this.loginService
				.licenseAvailables(agent, (res:any) => { 
					console.log("res is",res);
	
					if (res['errors'] != undefined) {
						this.showSpinner = false
						this.snackBar.open(
							res['errors'][0].message,
						  'Close',
						  {
							duration: 5000, // notification will automatically close after 5 seconds
							horizontalPosition: 'center', // position the notification at the right corner of the screen
							verticalPosition: 'top', // position the notification at the top of the screen
						  }
						);
						this.gaService.sendErrorDisplayEvent({ page_name: "register",message:res['errors'][0].message, type: "form_validation" });
						return;
					}
					else {
						console.log("Agent details are",agent);
						this.loginService
						.register(agent, (res:any) => { 
	
							this.showSpinner = false
							this.showRegistration = false
							//Registration successful. Please check your email to verify your account.
							this.snackBar.open(res?.message?res.message:'Request has been sent!', 'Close', {
							duration: 5000, // notification will automatically close after 5 seconds
							horizontalPosition: 'center', // position the notification at the right corner of the screen
							verticalPosition: 'top' // position the notification at the top of the screen
							});
							this.nameControl.reset();
							this.firstNameControl.reset();
							this.lastNameControl.reset();
							this.mlsControl.reset();
							this.emailControl.reset();
							this.phoneControl.reset();
							// this.passwordControl.reset();
							this.showRegistration = false;
							this.router.navigate(['login'])
	
						}, (err) => {
	
							this.showSpinner = false
	
							this.error = err;
							this.gaService.sendErrorDisplayEvent({ page_name: "register",message:this.error, type: "form_validation" });
						})
					}
	
				}, (err) => {
	
					this.showSpinner = false
	
					this.error = err
					this.gaService.sendErrorDisplayEvent({ page_name: "register",message:this.error, type: "form_validation" });
					console.log("error is",this.error);
				})
		}

		

	}

	showCheckmark(next) {

	    this.showDone = true
	    setTimeout(() => {

	    	this.showDone = false

	    	if ( next != null ) {

	    		// Pass "self" context
	    		next(this)
	    	}

	    }, 3000)
	}

	cancelRegistration() {
		this.error = null
		// this.showRegistration = false
    this.router.navigate(['login'])
		this.emailControl.clearValidators()
		// this.passwordControl.clearValidators()
		this.nameControl.reset()
		this.firstNameControl.reset()
		this.lastNameControl.reset()
		this.phoneControl.reset()
		this.emailControl.reset()
		// this.passwordControl.reset()

	}

	onEnter() {
    this.onRegister() 
  }
  formStartEvent(){
	this.gaService.sendFormStartEvent({formType:'sign_up',disType:'page'});
  }
  pageCtaClick(event:Event){//cta 7, cta 6
	event.stopPropagation();
	const element = (event?.target as HTMLElement);
	const roleValue = element.getAttribute('role');
	const elementName =element?.innerText.includes(' ')?element?.innerText?.trim().split(' ').join('_').toLowerCase():element?.innerText?.toLowerCase()
	this.gaService.sendCtaClickEvent({page_name:'register'},{cta_type:roleValue,cta_location:'sign_up_form',cta_description:elementName});
	}

}

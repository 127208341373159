import { Injectable, OnInit } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { HttpClient,  HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { Authorization } from '../authorization'
import { LoginService } from '../services/login.service'
import { graphQlFormat }  from '../graphQlFormatting'
import { environment } from '../../environments/environment'
import { ServiceErrors } from './service-errors'

@Injectable({
  providedIn: 'root'
})
export class ClientService {

	private graphApi:string = environment.graphApi;

	authorization: Authorization

	constructor(private loginService: LoginService, private http: HttpClient) { 
		this.checkAuth()
	}


	checkAuth () {
		this.authorization = this.loginService.getAuthorization()
		if ( !this.authorization?.token ) { console.error("No auth token present") }
	}

	/**
 	 Fetch the list of clients for the logged in agent
	*/
	fetchClients(clientIds?) {

		this.checkAuth()

		// Empty if none passes
		clientIds = clientIds ?? []

	  	var addressFields = ["fullAddress",
			"streetNumber",
			"streetName",
			"unitNumber",
			"zip",
			"crossStreet",
			"city",
			"county",
			"state",
			"country"]
		let addressJoins = `{ ${addressFields.join(" ")} }`
		let photoFields = ["url", "id", "type", "displayOrder", "mediaKey", 'longDescription', 'isTour', 'category']
		let photoJoins = `{ ${photoFields.join(" ")} }`

		let propertyFields = `id listingId listingKey dataSource originatingMls propertyFeatures address ${addressJoins} propertyPhotos ${photoJoins}`

    	let fields = ["id", "firstName", "lastName", "createdAt", "phone", "email", 
    		"avatar { url }", 
    		"clientTimeframe { id name }", 
    		"address { id fullAddress streetNumber streetName unitNumber zip city state country }",
    		// `clientFavorites { favoriteStatus property { ${propertyFields} } }`,
    		// `clientComments { id comments property { ${propertyFields} } }`,
			`clientcategory { category{ id name } clientsubcategory { subcategory { id name } } },`

		]
  

		let query = `query { clients (clientIds: ${ JSON.stringify(clientIds) } ) { ${fields.join(' ')} } }`
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	/**
 	 Get the list of properties that the client will or has seen.
	*/
	fetchPropertiesForClient(clientId) {

		this.checkAuth()

	  	var addressFields = ["fullAddress",
			"streetNumber",
			"streetName",
			"unitNumber",
			"zip",
			"crossStreet",
			"city",
			"county",
			"state",
			"country"]
		let addressJoins = `{ ${addressFields.join(" ")} }`
		let photoFields = ["url", "id", "type", "displayOrder", "mediaKey", 'longDescription', 'isTour', 'category']
		let photoJoins = `{ ${photoFields.join(" ")} }`
		let propertyFields = `id listingId listingKey dataSource originatingMls propertyFeatures address ${addressJoins} propertyPhotos ${photoJoins}`

    	// let fields = [
    	// 	"id", 
    	// 	`clientFavorites { favoriteStatus }`,
    	// 	`clientComments { id comments }`,
    	// 	`propertyRatings { starRating }`,
    	// 	`${propertyFields}`
		// ]
		//fields.join(' ')
		let updatedFields=`id property {  id listingKey  address ${addressJoins}  propertyFeatures propertyPhotos ${photoJoins}}
		 clientFavorites {  id   favoriteStatus  } propertyRatings {  id starRating } `

		let query = `query { propertiesForClient (clientId: "${ clientId }" ) { ${updatedFields} } }`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}


	addClient(client,selectedData) {
		const catIdArray = selectedData.catId.map(Number);
		const subCatIdArray = selectedData.subCatId.map(subArr => subArr.map(Number));
		
		// Convert arrays to string representations with square brackets
		const catIdString = `[${catIdArray.join(', ')}]`;
		const subCatIdString = `[${subCatIdArray.map(subArr => `[${subArr.join(', ')}]`).join(', ')}]`;		
		
		this.checkAuth()

		// let address = `{ fullAddress : "${client.address}" }`
		// let address = client.address.map( x => { return `{ fullAddress: "${x.fullAddress}", zip: ${x.zip}, city: "${x.city}", state: "${x.state}" }`})

		let address = client.address.map(x => {
			const zipCode = x.zip !== null && x.zip !== "" ? `${x.zip}` : '';
			const state = x.state !== null && x.state !== "" && x.state !== undefined? `${x.state}` : '';
			const city = x.city !== null && x.city !== "" && x.city !== undefined? `${x.city}` : '';
			return `{ fullAddress: "${x.fullAddress}", zip: "${zipCode}", city: "${city}", state: "${state}" }`;
		});
		
	  	delete client.id
	  	delete client.checked
	  	delete client.address
	  	delete client.picture
	  	delete client.clientFavorites
	  	delete client.properties
		delete client.clientcategory

		console.log("client details are",client);
		

		client.email = client.email.toLowerCase();
	  	
		let fields = graphQlFormat(client)


		let query = `mutation { createClient ( ${fields.join(' ')} address: ${address} catId:${catIdString} subCatId: ${subCatIdString}) { id } }`
		
		let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}

	deleteClients( clientList ) {

		this.checkAuth()

		let query = `mutation { deleteClients ( clientList: [${clientList.join(", ")} ] )}`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}

	/**
 	 Joins a client to a tour
	*/
	addClientsToTour(clients, tour) {

		this.checkAuth()

		let query = `mutation { addClientsToTour (clients: [${clients.join(',')}] tour: ${tour} ) }`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	/**
 	 Removes a client(s) from a tour
	*/
	removeClientsFromTour(clients, tour) {

		this.checkAuth()

		let query = `mutation { removeClientsFromTour (clients: [${clients.join(',')}] tour: ${tour} ) }`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}


	/**
 	 Update Client info
	*/
	updateClient(client,selectedData) {

		const catIdArray = selectedData.catId.map(Number);
		const subCatIdArray = selectedData.subCatId.map(subArr => subArr.map(Number));
		
		// Convert arrays to string representations with square brackets
		const catIdString = `[${catIdArray.join(', ')}]`;
		const subCatIdString = `[${subCatIdArray.map(subArr => `[${subArr.join(', ')}]`).join(', ')}]`;	

		this.checkAuth()

		client.clientTimeframe = Number(client.timeframe)

		delete client.clientComments
		delete client.clientFavorites
		delete client.timeframe
		delete client['avatar']
		delete client.picture
		delete client.createdAt
		delete client.properties
		delete client.clientcategory

		// client.address = client.address.map( x => { return `{ fullAddress: "${x.fullAddress}", zip: ${x.zip}, city: "${x.city}", state: "${x.state}" }`})
		client.address = client.address.map(x => {
			console.log("x is",x);
			const zipCode = x.zip !== null && x.zip !== "" ? `${x.zip}` : '';
			const state = x.state !== null && x.state !== "" && x.state !== undefined? `${x.state}` : '';
			const city = x.city !== null && x.city !== "" && x.city !== undefined? `${x.city}` : '';
			return `{ id:${x.id},fullAddress:"${x.fullAddress}", zip:"${zipCode}", city:"${city}", state:"${state}" }`;
		  });

		client.email = client.email.toLowerCase();

		const clientUpdate = graphQlFormat(client).join(' ')

		let query = `mutation { updateClient (client: { ${clientUpdate} catId:${catIdString} subCatId: ${subCatIdString}}  ) { id firstName lastName email phone clientTimeframe { id name } avatar { url } address { id fullAddress streetNumber streetName unitNumber zip city state country } clientcategory { category{ id name } clientsubcategory { subcategory { id name } } } }}`
		let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}


	/**
 	 Get the list of client timeframes for use
	*/	
	getClientTimeframes() {

		this.checkAuth()

		let query = `query { getClientTimeframes { id name } }`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}


	/**
 	 Send an photo that the client took of a property to the server
	*/
	uploadPropertyPhoto(data, clientId, propertyId) {


		const fileExtension = (s) => {

			const lastDot = s.lastIndexOf('.');

			const filename = s.substring(0, lastDot);
			const ext = s.substring(lastDot + 1);

			return {
				filename : filename,
				extension: ext
			}
		}

		if ( data == null ) {
			return
		}
		
		this.checkAuth()

	  	let options = { 
	  		headers : { 
  				"Authorization" : `Bearer ${this.authorization.token}`,
  				"x-metadata-client-id" : clientId,
  				"x-metadata-property-id" : propertyId,
  				"x-metadata-file-extension" : fileExtension(data.name).extension
	  		}
	  	}
	  	return this.http.post(environment.restApi.propertyPhotoUpload, data, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}


	/**
 	 Add a rating to a property, including a star
	*/
	rateProperty ( rating, clientId, propertyId ) {

		this.checkAuth()

		let query = `mutation { addClientRatingToProperty ( rating: ${rating} client: ${clientId} property: ${propertyId} ) { id name } }`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}

	/**
 	 Set the favorite status of a property
 	  	updateFavoriteStatus( status: Int!, client: ID!, property: ID! ): ClientFavorites

	*/
	favoriteProperty ( favoriteStatus, clientId, propertyId ) {

		this.checkAuth()

		let query = `mutation { updateFavoriteStatus ( status: ${favoriteStatus} client: ${clientId} property: ${propertyId} ) { id favoriteStatus clients { id } property { id } } }`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}
	/*** POI category get*/
	getPoiCategory(){
		this.checkAuth();

		let query = `query{ getCatSubCategories{  id name subcategory{ id name }} }`;
		let body = { query: query };
		let options = {
			headers: { Authorization: `Bearer ${this.authorization?.token}` },
		};

		return this.http
			.post(this.graphApi, body, options)
			.pipe(catchError(ServiceErrors.handleError));
	}
		

}

<mat-card>
  <mat-card-content>
    <div class="content-container">
      <div class="title">
        <h1 tabindex="0" class="customheading"><span class="heading-text">{{ property?.UnparsedAddress}} &nbsp;|&nbsp; <b>{{ formatPrice(property?.propertyFeatures['featureData'].ListPrice) }}</b>
            <span [class]="priceChangeColor(property)" style="padding: 0px; font-size: 22px;">
              {{ priceChangeArrow() }}
            </span>
          </span>
          <div class="rightTour">
            <!-- <button class="addtour me-2" mat-flat-button [disabled]="isWishlistBtnClicked" (click)="isAddToWishList?removeWishlist(property):onAddToWishList(property)" tabindex="0">
              <i [class]="isAddToWishList?'bi bi-check text-success':'bi bi-plus'"></i> <span>{{isAddToWishList?'Added':'Add'}} to Wishlist</span>
            </button> -->
            <span class="addtour" mat-flat-button (click)="onAddToTour(property)" tabindex="0">
              <span>Add to tour<i class="bi bi-plus plusicon" style="color:#0C4675;"></i></span>
              <!-- <i class="bi bi-arrow-right"></i> -->
            </span>
          </div>
        </h1>
      </div>
      
        
        <div class="row ImageGrid-price_wrapper mt-3" style="display: block;">
          <div class="col-md-8 col-lg-8 desktop-gap" style="float:left">
          <app-image-grid *ngIf="property != null" [images]="property.propertyPhotos" (onCtaClick)="onArrowClick($event)" [config]="imageGridConfig" [isAddToWishList]="isAddToWishList" [isWishlistBtnClicked]="isWishlistBtnClicked" [property]="property" (addToWishList)="onAddToWishList($event)" (removeFromWishList)="removeWishlist($event)"></app-image-grid>
          </div>
          <div class="col-md-4 col-lg-4 key-property-details" style="float:left">
            <h1 style="margin-bottom: 30px;" class="mt-3">Other Details:</h1>
            <div class="property-tabs"><span>{{ property?.propertyFeatures['featureData'].BedroomsTotal || 'N/A'}}</span> <br>Beds</div>
            <div class="property-tabs"><span>{{ bathroomCalculation(property) }}</span><br>Baths</div>
            <div class="property-tabs"><span>{{ property?.propertyFeatures['featureData'].LivingArea || 'N/A' }}</span><br>Sq. Ft</div>
            <!-- <div class="property-tabs"><span [ngClass]="((property?.propertyFeatures['featureData'].LotSizeAcres).length > 4)?'fontsizeChange':''">{{ property?.propertyFeatures['featureData'].LotSizeAcres || 'N/A' }}</span><br>Lot Size</div> -->
            <div class="property-tabs"><span>{{ property?.propertyFeatures['featureData'].LotSizeAcres != null ? (property.propertyFeatures['featureData'].LotSizeAcres | number:'.2-2') : 'N/A' }}</span><br>Lot Size</div>
        </div>
        <div class="col-lg-12" style="float:left">
          <h1 class="mb-3"style="margin-left: 0px !important;">About the Property:</h1>
          <p class="formatted-remarks"
              [innerHTML]="formatRemarks(property?.propertyFeatures['featureData'].PublicRemarks)"></p>
        </div>
        <div class="map-add-details-wrap row">

          <div class="col-md-6">
            <h1 _ngcontent-ibu-c325="" style="margin-left: -0.75rem !important;margin-right: -0.75rem;width: auto;" class="mt-3 mb-3"> Locate Property: </h1>
            <div id="map" class="mt-2">
              <!-- <google-map height="250px" width="100%" [center]="property?.center">
                <map-marker [position]="property?.center"></map-marker>
              </google-map>  -->
            </div>
          </div>

          <div class="col-md-6">
            <h1 _ngcontent-ibu-c325="" style="margin-left: -0.75rem !important;margin-right: -0.75rem;width: auto;" class="mt-3 mb-3"> Additional Details: </h1>
              <div class="col-sm-12 seller-container">
                <div class="seller-heading"><strong>Details of Seller's Agent :</strong></div>
                <div class="mt-3" tabindex="0"><div class="seller-details" style="word-break: break-all;"><strong>Name&nbsp;:&nbsp;</strong><span>{{property?.propertyFeatures.featureData.ListAgentFullName ?? "N/A"}}</span></div></div>
                <div class="mt-3" tabindex="0"><div class="seller-details" style="word-break: break-all;"><strong>Email&nbsp;:&nbsp;</strong><span>{{property?.propertyFeatures.featureData.ListAgentEmail ?? "N/A"}}</span></div></div>
                <div class="mt-3" tabindex="0"><div class="seller-details" style="word-break: break-all;"><strong>Phone&nbsp;:&nbsp;</strong><span>{{property?.propertyFeatures.featureData.ListAgentPreferredPhone ?? property?.propertyFeatures.featureData.ListAgentDirectPhone ?? "N/A"}}</span></div></div>
              </div>
              <div class="details-wrapper mt-1">
              <div class="detail-item" *ngFor="let detail of propertyDetails(property) | keyvalue">
                <div>
                  <strong>{{detail.key}} </strong><span>{{detail?.value}} </span>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="container mt-2">

     
      <div class="column column-3">
        <!-- Star Ratings & Heart -->
        <div class="right rating-and-stars" *ngIf="clientId != null">
          <div class="star-set">
            <mat-icon *ngFor="let i of [1,2,3,4,5]" class="empty-star {{ getStarStatus(i) }}">{{ getStarStatus(i)
              }}</mat-icon>
          </div>
          <div class="heart-set">
            <mat-icon *ngIf="clientId != null" class="heart {{ getFavStatus() }}">{{ getFavStatus() }}</mat-icon>
          </div>
        </div>
        <!-- Client notes & comments -->
        <div *ngIf="clientId != null" class="client-comments">
          <mat-list *ngFor="let comment of property?.clientComments">
            <mat-card>
              <mat-card-title>{{comment.clients.firstName}} said,</mat-card-title>
              <mat-card-subtitle>{{ formatTimestamp(comment.createdAt) }}</mat-card-subtitle>
              <mat-card-content>
                "{{comment.comments}}"
              </mat-card-content>
            </mat-card>
          </mat-list>
        </div>
        <div *ngIf="clientId != null" class="client-photos">
          <app-image-grid *ngIf="property != null" [images]="property.clientPhotos"
            (onImageClick)="onOpenClientImages($event)"></app-image-grid>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <mat-error>{{error}}</mat-error>
  </mat-card-footer>
</mat-card>

<app-spinner [show]="showSpinner"></app-spinner>
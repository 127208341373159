<div [class.scroll-lock]="enlargedImage">  
	<div class="title-bg-color">
		<span>Property&nbsp;{{propertyIndex}}:&nbsp;</span> {{showing?.property?.address?.fullAddress}}
		<img [src]="favoriteStatus(showing)" alt="Favorite Icon" width="35" style="float:right;margin-top:-6px;">
	</div>
	<div class="price-details">{{listPrice()}}</div>
	<div>
		<h1 class="heading_2">Property Photos <div class="rating-star">Property rating &nbsp;<div [innerHTML]="RatingStatus(showing)"></div></div></h1>
		<app-image-viewer-element class="image-viewer" [images]="getImageSet()"	[thumbnailIndex]="thumbnailImageIndex">
		</app-image-viewer-element>
	<div>

	<div>
		<h1 class="heading_2">Your comments:&nbsp;<span *ngFor="let comment of comments()">{{ comment }} <br></span></h1>
	</div>

	<div>
		<ng-container *ngIf="showing['clientPhotos'].length > 0">
			<h1 class="heading_2">Your Photos:</h1>
			<div>
				<app-image-row [selected]="areClientPhotosSelected()" [images]="filterImagesByType(showing['clientPhotos'], 'jpg')" (onImageClick)="onSelectClientThumbnail($event)">
				</app-image-row>
			</div>
		</ng-container>
	</div>

	<div>
		<ng-container *ngIf="showing['clientPhotos'].length > 0">
			<h1 class="heading_2">Your Videos:</h1>
			<div>
				<app-image-row [selected]="areClientPhotosSelected()" [images]="filterImagesByType(showing['clientPhotos'], 'mp4')" (onImageClick)="onSelectClientThumbnail($event)"></app-image-row>
			</div>
		</ng-container>
	</div>
	<div *ngIf="enlargedImage" class="enlarged-image">
		<img [src]="enlargedImage" (click)="closeEnlargedImage()" class="enlarged-img">
	</div>

</div>
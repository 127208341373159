import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';


import { LoginService } from '../services/login.service'
import { AgentService } from '../services/agent.service'
import { PhotosService } from '../services/photos.service'
import { MlsService } from '../services/mls.service'
import { environment } from '../../environments/environment'

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Client } from '../client'
import { Property } from '../propertySearch'
import { Agent, License } from '../agent'
import { Mls } from '../mls'

import { AddressAutocompleteComponent } from '../address-autocomplete/address-autocomplete.component'
import { SpinnerComponent } from '../spinner/spinner.component'
import { CheckmarkComponent } from '../checkmark/checkmark.component'
import { MatSelect } from '@angular/material/select';
import { AdminService } from '@app/services/admin.service';
import { Organization } from '@app/user';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { delay } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarMessageComponent } from '../components/snackbar-message.component';
@Component({
  selector: 'app-agent-profile',
  templateUrl: './agent-profile.component.html',
  styleUrls: ['./agent-profile.component.css']
})
export class AgentProfileComponent implements OnInit {

	private imagePlaceholder = environment.imagePlaceholder

	error: string = null

	selectedFile: File = null

	isLicenseFieldsInvalid: boolean = false;
	isLicenseInvalid: boolean = false;

	orgList: Organization[];



	FirstName: string;
	LastName: string;
	Image: any;
	email: any;
	phone: any;
	address: any;
	city: any;
	state: any;
	zip: any;
	Licenses: any;

	/**
 	 All possible mls
	*/	
	mlsList: Mls[] = null

	emailControl = new UntypedFormControl('')
	firstNameControl = new UntypedFormControl('')
	lastNameControl = new UntypedFormControl('')
	phoneControl = new UntypedFormControl('')
	mlsControl = new UntypedFormControl('')

	private mlsToRemove: Mls[] = []
	private licenseToRemove: License[] = []

	/**
 	 Agent id pulled from url path
	*/
	private _agentLinkName: string = null

	set	agentLinkName( newValue: string ) {

		this._agentLinkName = newValue

		if ( newValue != null ) {
			this.fetchExistingAgent()
		}
	}

	get agentLinkName(): string {
		return this._agentLinkName
	}

	agent: Agent = {
		id: null,
		userName: null,
		firstName: null,
		lastName: null,
		email: null,
		phone: null,
		mls: null,
		org: null,
		address: [{
			fullAddress: '',
			streetNumber: null,
			streetName: '',
			unitNumber: '',
			zip: null,
			city: '',
			state: '',
			country: ''
		}],
		fullName: null,
		picture: null,
		link: null,
		license: null,
	}

	/**
 	 Value for when the spinner should be shown
	*/
	showSpinner = false


	showCheck = false

	/**
 	 Which logo to use
	*/
	logo = "./assets/livepad-logo-black.png"

	/**
 	 Extra styling options for the image
	*/	
	logoStyle = { 
		"height" : "40px",
		"opacity": "0.2"
	}

	buttonStyle = {
		"height": "60px",
		"width": "auto"
	}


	/**
 	 Toggle for editing agent information
	*/
	editButtonPressed = false

	constructor( private router: Router, 
	  	private route: ActivatedRoute,
		private loginService: LoginService, 
		private agentService: AgentService,
		private domSanitizer: DomSanitizer,
		private photosService: PhotosService,
		private adminService: AdminService,
		private mlsService: MlsService,
		private cdr: ChangeDetectorRef,
		private changeDetector: ChangeDetectorRef,
		private snackBar: MatSnackBar,
		private gaService:GoogleAnalyticsService ) { 

	}

	ngOnInit(): void {

		// Store the initial name values
		this.FirstName = this.agent.firstName;
		this.LastName = this.agent.lastName;
		this.Image = this.agent.picture;
		this.email = this.agent.email;
		this.phone = this.agent.phone;
		this.address = this.agent.address[0].fullAddress;
    	this.city = this.agent.address[0].city;
		this.state = this.agent.address[0].state;
		this.zip = this.agent.address[0].zip;
		this.Licenses = this.agent.license;

		// Get the existing agent information if it is accessible by this agent
		this.route
			.params
			.subscribe( (params) => {
				this.agentLinkName = params['agentLinkName']
			})

		this.fetchMlsList()
	}

	@ViewChild("Email") Email: ElementRef;
	@ViewChild('fileInput') fileInput!: ElementRef;
	onKey(event: KeyboardEvent) {
		event.preventDefault();
		if (event.key === "Tab") {
			this.Email.nativeElement.value = "Email";
			this.changeDetector.detectChanges();
			this.Email.nativeElement.focus();
		}	
	}

	focusOnMemberships() {
		const membershipHeading = document.querySelector('.section-header h2');
		if (membershipHeading) {
		  (membershipHeading as HTMLElement).focus();
		}
	  }

	/**
 	 Hide the edit button if this is an unauthenticated viewing ( public profile )
	*/
	canEdit() {
		return this.loginService.authorization?.token != null && this.loginService.user.agent.link == this.agentLinkName
	}

	gotoForm() {
		this.router.navigate(['//member-licence']);
	  }

	isValidImageUrl(url: string): boolean {
		const regex = /^(https:\/\/dkx2r6ndffbfa\.cloudfront\.net\/assets\/images\/(DEV|STAGE|UAT|PROD)\/avatar\/agents\/\d+_\d+\.(jpg|png|jpeg))|(blob:.+)$/i;
		return regex.test(url);
	}


	/**
 	 Used to toggle disabled fields
	*/
	isEditing() {

		return this.editButtonPressed == true
	}

	getActionButtonTitle() {
		if ( this.isEditing() ) {
			return "SAVE"
		} else {
			return "CREATE"
		}
	}

	/**
 	 Press the edit button on an existing client
	*/
	toggleEdit() {
		this.FirstName = this.agent.firstName;
  		this.LastName = this.agent.lastName;
		this.Image = this.agent.picture;
		this.email = this.agent.email;
		this.phone = this.agent.phone;
		this.address = this. agent.address[0].fullAddress;
      	this.city = this. agent.address[0].city;
		this.state = this. agent.address[0].state;
		this.zip = this. agent.address[0].zip;
		this.Licenses = [...this.agent.license];
		this.editButtonPressed = this.editButtonPressed == true ? false : true
	}

	/**
 	 "New Client" or "Joe Blow"
	*/
	getTitle() {
		return `${this.agent?.firstName} ${this.agent?.lastName}`
	}

	/**
 	 Show our checkmark completion animation. Auto closing.
	*/
	showCheckmark(next) {

		this.showCheck = true
		setTimeout(function(self) {

			self.showCheck = false

			if ( next != null ) {
				next(self)
			}
		}, 3000, this)

	}

	/**
 	 Just go back to clients list
	*/
	onCancelEdit() {
		if (this.isEditing()) {
		  this.gaService.sendCtaClickEvent({page_name:`agent-${this.agent?.firstName}-${this.agent?.lastName}`},{cta_type:'button',cta_location:'agent_profile_edit', cta_description:'cancel'});//cta 72
		  // Restore the previous name in the title
		  this.agent.firstName = this.FirstName;
		  this.agent.lastName = this.LastName;
		  this.agent.picture = this.Image;
		  this.agent.email = this.email;
		  this.agent.phone = this.phone;
		  this.agent.address[0].fullAddress = this.address;
		  this.agent.address[0].city = this.city;
		  this.agent.address[0].state = this.state;
		  this.agent.address[0].zip = this.zip;
		  this.agent.license = this.Licenses;		  
		  // Toggle the editing mode
		  this.toggleEdit();
		  this.isLicenseFieldsInvalid = false;
		  this.isLicenseInvalid = false;
		}
	}

	/**
 	 Save button pressed
	*/
	onConfirmEdit() {
		this.gaService.sendCtaClickEvent({page_name:`agent-${this.agent?.firstName}-${this.agent?.lastName}`},{cta_type:'button',cta_location:'agent_profile_edit', cta_description:'save'});//cta 72
		if ( this.isEditing() ) {
			this.error = null
			// const isLicenseFieldsEmpty = !this.agent.license[0]?.licenseNumber || !this.agent.license[0]?.licenseRegion;
			// const isLicenseEmpty = this.agent.license.some(license => {
			// return !license.licenseNumber || !license.licenseRegion;
			// });
			this.firstNameControl.setValidators( [Validators.required] )
			this.lastNameControl.setValidators( [Validators.required] )
			this.phoneControl.setValidators([Validators.required, Validators.pattern('^[0-9]{10}$')]);
			// this.emailControl.setValidators([Validators.required, Validators.email])
			this.emailControl.setValidators([Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]);

			this.firstNameControl.updateValueAndValidity()
			this.lastNameControl.updateValueAndValidity()
			this.emailControl.updateValueAndValidity()
			this.phoneControl.updateValueAndValidity()


			this.firstNameControl.markAsTouched()
			this.lastNameControl.markAsTouched()
			this.phoneControl.markAllAsTouched()
			this.emailControl.markAsTouched()

			if ( this.firstNameControl.invalid || 
				this.lastNameControl.invalid || 
				this.phoneControl.invalid || 
				this.emailControl.invalid ) {
				return
			}

			// if (isLicenseFieldsEmpty ||
			// 	isLicenseEmpty ){
			// 		this.isLicenseFieldsInvalid = true;
			// 		this.isLicenseInvalid = true;
			// 		return
			// 	}

			if ( this.selectedFile != null ) {
				this.saveUpdateWithAvatar()
			} else {
				this.saveUpdate()
			}
			this.isLicenseFieldsInvalid = false;
			this.isLicenseInvalid = false;
			return
		}

	}
	// onConfirmEdit() {
	// 	if (this.isEditing()) {
	// 	  this.error = null;
		  
	// 	  const isLicenseFieldsEmpty = this.agent.license.some(license => {
	// 		return !license.licenseNumber || !license.licenseRegion;
	// 	  });
	  
	// 	  this.firstNameControl.setValidators([Validators.required]);
	// 	  this.lastNameControl.setValidators([Validators.required]);
	// 	  this.phoneControl.setValidators([Validators.required]);
	// 	  this.emailControl.setValidators([Validators.required, Validators.email]);
	  
	// 	  this.firstNameControl.updateValueAndValidity();
	// 	  this.lastNameControl.updateValueAndValidity();
	// 	  this.emailControl.updateValueAndValidity();
	// 	  this.phoneControl.updateValueAndValidity();
	  
	// 	  this.firstNameControl.markAsTouched();
	// 	  this.lastNameControl.markAsTouched();
	// 	  this.phoneControl.markAllAsTouched();
	// 	  this.emailControl.markAsTouched();
	  
	// 	  if (
	// 		this.firstNameControl.invalid ||
	// 		this.lastNameControl.invalid ||
	// 		this.phoneControl.invalid ||
	// 		this.emailControl.invalid ||
	// 		isLicenseFieldsEmpty
	// 	  ) {
	// 		this.isLicenseFieldsInvalid = true;
	// 		return;
	// 	  }
	  
	// 	  if (this.selectedFile != null) {
	// 		this.saveUpdateWithAvatar();
	// 	  } else {
	// 		this.saveUpdate();
	// 	  }
	// 	  return;
	// 	}
	//   }


	/**
 	 Previews uploaded pic
	*/
	onFileChanged(event) {
		if (!event.target.files[0]?.name?.match(/\.(jpg|jpeg|png)$/i) && event.target.files[0].type !== 'text/plain') {
			// const message = ` File format not supported.You can upload only jpg, jpeg, png extension file `;
			this.snackBar.openFromComponent(SnackbarMessageComponent,  {
				duration: 5000, // notification will automatically close after 5 seconds
				horizontalPosition: 'center', // position the notification at the right corner of the screen
				verticalPosition: 'top', // position the notification at the top of the screen
				panelClass: ['file-error-snackbar'] // add a custom CSS class to the notification
			});
			event.preventDefault();
			this.fileInput.nativeElement.value = '';
			return;
		}
		this.selectedFile = <File>event.target.files[0];
		let blob = URL.createObjectURL(this.selectedFile)

		//TODO: Secure this
		this.agent.picture = this.domSanitizer.bypassSecurityTrustResourceUrl(blob)
	}

	formatPhoneNumber(phoneNumber) {
		if (phoneNumber && phoneNumber.length === 10) {
			const formattedNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
			return formattedNumber;
		}
		return phoneNumber;
	}


	private updateAgent(newAgent) {

		console.log("new agent inside the update agent method is",newAgent);

		if (!newAgent.address) {
			newAgent.address = { fullAddress: '', city: '', zip: '', state:'' };
		}
	    newAgent.picture = newAgent.avatar?.url ?? this.imagePlaceholder
	    // newAgent.address = newAgent.address?.fullAddress ?? null
		newAgent.address = newAgent.address ? [newAgent.address] : [];
	    newAgent.license = newAgent.license?.length > 0 ? newAgent.license : []
	    newAgent.mls = newAgent.mls?.length > 0 ? newAgent.mls.map( x => { return x.contractedMls }) : []
	    this.agent = newAgent

	    this.loginService.agentAvatarUpdated(this.agent.picture)
	}
	/**
 	 Go fetch the agent that was passed to us in our path
	*/
	fetchExistingAgent() {

		// Populate timeframes
		this.agentService
			.fetchAgent( this.agentLinkName )
			.subscribe(response => { 
	  
	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
	  				this.error = response["errors"][0].message
	  				if ( this.error.toLowerCase().includes('authenticated') ) {
						this.router.navigate(['/login'])
	  				} 
	  				return
	  			}

			    let foundAgent = response["data"]["agent"]
				console.log("found agent is",foundAgent);
				let data = this.loginService.user;
				console.log("the data we are getting is",data);
				foundAgent.mls = data.usermls;

			    // Agent not found
			    if ( foundAgent == null ) {
			    	this.router.navigate([ '/page-not-found'] )
			    	return
			    }

			    this.updateAgent(foundAgent)

	  		})
	}

	/**
 	 Set the actual mls object, including id into the index once chosen.
	*/
	onSelectedMls(i, event) {

		this.agent.mls[Number(i)] = this.mlsList.find( x => { return x.mlsName == event.value } )

	}


	/**
 	 Check if this mls has already been added to the agent
	*/
	isMlsAdded(mls) {

		let found = this.agent?.mls.find( y => { return y.mlsName == mls.mlsName } )
		return found != null
	}

	/**
 	 Pushes on an empty mls object and waits for its value to be selected
	*/
	onAddMls() {

		// Max number of mls supported
		if ( this.agent?.mls.length >= this.mlsList.length ) {
			return
		}

		this.agent.mls.push({
			id: null,
			mlsName: null
		})
	}

	// onAddLicense() {

	// 	this.agent.license.push({
	// 		id: null,
	// 		licenseNumber: null,
	// 		licenseRegion: null
	// 	})
	// }
	onAddLicense() {
		this.gaService.sendCtaClickEvent({page_name:`agent-${this.agent?.firstName}-${this.agent?.lastName}`},{cta_type:'icon',cta_location:'agent_profile_edit', cta_description:'add_license'})
		const newLicense = {
		  id: null,
		  licenseNumber: '',
		  licenseRegion: ''
		};
	
		// Add the new license to the array
		this.agent.license.push(newLicense);
	
		// Reset license fields validation
		this.isLicenseFieldsInvalid = false;
		this.isLicenseInvalid = false;
	
		// Trigger change detection to update the view
		this.cdr.detectChanges();
	  }

	/**
 	 Remove an mls from this agent
	*/
	onRemoveMls(agentMls, i, event) {

		this.agent.mls.splice(Number(i), 1)
	}

	/**
 	 Remove a license from this agent
	*/
	onRemoveLicense(license, i, event) {
		this.agent.license.splice(Number(i), 1)
	}
	// onRemoveLicense(license, i, event) {
	// 	if (this.agent.license.length > 1) {
	// 	  this.agent.license.splice(Number(i), 1);
	// 	}
	// }

    fetchMlsList() {

    	this.error = null

		this.mlsService
			.fetchMlsList()
			.subscribe( (response) => {

	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
	  				this.error = response["errors"][0].message
	  				return
	  			}

	  			// this.mlsList = response['data']['contractedMlsList']
				this.mlsList = response['data']['contractedMlsList'].map(
				(mls: Mls) => ({
					...mls,
					checked: false,
					disabled:true,
				})
				);

			})
    }
	/**
 	 Save the updates when the avatar image has been edited.
	*/
	saveUpdateWithAvatar() {

		// Reset error
		this.error = null

		this.showSpinner = true

		// Prep the upload
		let upload = 
			this.photosService
			.uploadAvatar(this.selectedFile, null, this.agent.id )
			
		// Upload the avatar if the user selected one, async. We don't care when this completes.
		if ( upload != null ) {
			upload.subscribe( avatar => {

	  			// Capture server error messages
	  			if ( avatar["errors"] != undefined ) {
	  				this.error = avatar["errors"][0].message
					this.showSpinner = false

	  				return
	  			}

	  			this.saveUpdate()

			})

		} 
	}

	/**
 	 Save the updates when the avatar image has not been edited.
	*/
	saveUpdate() {

		// Reset error
		this.error = null

		this.showSpinner = true

		this.agentService
			.updateAgent(this.agent)
			.pipe(delay(3000))
			.subscribe(response => {
	  
				this.showSpinner = false

	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
	  				this.error = response["errors"][0].message

	  				return
	  			}

	  			const agent = response["data"]["updateAgent"]['agent']

				agent.mls = response["data"]["updateAgent"]['usermls'];

	  			this.updateAgent(agent)

				this.showCheckmark((self) => {
		  			self.toggleEdit()
				})
	  		})
	}
	
	//   getOrgList() {
	// 	this.adminService.getOrgDetail(Number(this.data1.orgData['id'])).subscribe({
	// 	  next: (res: Organization[]) => {
	// 		this.data = res['data']['getOrgData'][0]
	// 		this.selectedImage = this.data?.avatar?.url ? this.data.avatar.url : '';
	// 		console.log("the data we are getting is",this.data)
	// 		// .map((item) => ({
	// 		//   ...item,
	// 		//   isHovered: false,
	// 		// }));
	// 		// this.isDataLoaded = true;
	// 	  },
	// 	  error: (err: any) => {
	// 		console.log('error-object', err);
	// 	  },
	// 	});
	//   }
	  openModal:boolean = false;
	  protected selectedUser:any;
	

	onOrganizationSelected(selectedOrgId: any) {
		console.log('Selected Organization ID:', selectedOrgId);
		if(selectedOrgId == 0){
			return;
		}
		else {
			this.adminService.getOrgDetail(selectedOrgId).subscribe((res) => {
				const orgmls = res['data']['getOrgData'][0]['orgmls'];
				if (Array.isArray(orgmls)) {
					this.mlsList = orgmls.map(item => item.contractedMls);
				} else if (orgmls && orgmls.contractedMls) {
					this.mlsList = [orgmls.contractedMls];
				} else {
					this.mlsList = [];
				}
				console.log("mls list is",this.mlsList);
			});
		}
	  }


	onSendRequest(select1: MatSelect, select2: MatSelect): void {
		this.agentService
			.fetchAgent( this.agentLinkName )
			.subscribe(response => { 
	  
	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
	  				this.error = response["errors"][0].message
	  				if ( this.error.toLowerCase().includes('authenticated') ) {
						this.router.navigate(['/login'])
	  				} 
	  				return
	  			}

			    let foundAgent = response["data"]["agent"]
				console.log("found agent is",foundAgent);
				const selectedOrg = select1.value;
				const selectedMls2 = select2.value;
			  
				console.log('Selected MLS 1:', selectedOrg);
				console.log('Selected MLS 2:', selectedMls2);


		
		})
		this.openModal = false;
	  
		// You can now access the selected values for both mat-select elements.
	}
	typingStarted:boolean = false;
	fromStartFrom(){
	  if (!this.typingStarted) {
	  this.gaService.sendFormStartEvent({formType:'',disType:'page'});
	  this.typingStarted=true;
	  }
	}


}

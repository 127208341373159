import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Organization } from '@app/interface/organization';
import { ContactUs } from '@app/interface/contact-us';
import { AdminService } from '@app/services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css'],
})
export class ContactFormComponent implements OnInit {
  /** create user from field as reactive form */
  contactForm!: FormGroup;
  /** Mls control	*/
  orgList!: Organization[];
  /** Space to contain error message	*/
  error: string | null = null;
  /** submit form */
  submitted: boolean = false;
  /** Value for when the spinner should be shown	*/
  showSpinner: boolean = false;
  /**form submitted  */
  showDone:boolean=false;
  constructor(private fb: FormBuilder, private adminService: AdminService,private snackBar:MatSnackBar) {}
  ngOnInit() {
    /** set value to contactForm */
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      // firstName: ['', Validators.required],
      // lastName: ['', Validators.required],
      email: ['',
        [
          Validators.required,
          Validators.pattern(
            '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ],
      ],
      phone: ['', [Validators.pattern('^[0-9]{10}$')]],
      // organization: [[]],
      organization: [''],
      message: [''],
    });
    // this.fetchOrgList();
  }
  fetchOrgList() {
    this.error = null;
    this.adminService.orgList().subscribe((orgList) => {
      // Capture server error messages
      if (orgList['errors'] != undefined) {
        this.error = orgList['errors'][0].message;
        return;
      }
      this.orgList = orgList['data']['getOrgsList'];
      const noneOfTheAbove = orgList['data']['getOrgsList'].find(
        (organization) => organization.id == 1
      );
      this.orgList = orgList['data']['getOrgsList']
        .filter(
          (organization) => organization.status === 1 && organization.id != 1
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      if (noneOfTheAbove) {
        noneOfTheAbove.name = 'None of the Above';
        this.orgList.push(noneOfTheAbove);
      }
      // this.orgList.forEach((organization) => {
      //   if (organization.id == 1) {
      //     organization.name = "None of the Above";
      //   }
      //   });
    });
  }

  get getContactForm(): { [key: string]: AbstractControl } {
    return this.contactForm.controls;
  }
  @ViewChild('organization') organization: ElementRef;
  onKey(event: KeyboardEvent) {
    event.preventDefault();
    if (event.key === 'Tab') {
      // this.organization.nativeElement.value = 'organization';
      // this.changeDetector.detectChanges();
      this.organization.nativeElement.focus();
    }
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.contactForm.invalid) {
      this.showSpinner = false;
      return;
    }
    this.error = null;
    console.log("contactForm-object",this.contactForm.value);
    this.showSpinner=false;
    this.snackBar.open(
      'Your form has been successfully submitted.',
      'Close',
      {
        duration: 5000, // notification will automatically close after 5 seconds
        horizontalPosition: 'center', // position the notification at the right corner of the screen
        verticalPosition: 'top', // position the notification at the top of the screen
      }
    );
    this.showDone=true;
  }
}

<div class="mb-4">
    <div class="content-container" *ngIf="agent?.id !=null">
        <div class="title">
                <h1 tabindex="0">
                    <!-- <img src="assets/client-icon-new.svg" alt="Agent Icon" width="30" tabindex="0"> -->
                    {{ getTitle() }}
                <span class="editprofile" (click)="onClick($event)" tabindex="0">Edit Profile<i class="bi bi-pencil-fill icon-blue"
                 title="click here to edit"></i></span>
                </h1>
            </div>
    
        <div class="clientForm mt-2">
            <div class="row">
                <div class="col-lg-2 col-md-3">
                    <div class="imagePlacing" style="text-align:center;">
                       <!--  <mat-label tabindex="0">Change Image</mat-label> -->
                        <img class="image" alt="Agent avatar" tabindex="0" [src]="isValidImageUrl(agent.picture) ? agent.picture : './assets/avatar.jpg'"/>
                    </div>
                </div>
                <div class="col-lg-10 col-md-9 edit-profile mob-top">
                    <div class="row">
                        <div class="col-md-4">
                            <label [attr.for]="agent.firstName+agent?.id">First Name</label>
                            <div class="agent-details form-control" [attr.id]="agent.firstName+agent?.id">{{agent.firstName}}</div>
                        </div>
                        <div class="col-md-4">
                            <label>Last Name</label>
                            <div class="agent-details form-control">{{agent.lastName?agent.lastName:''}}</div>
                        </div>
                        <div class="col-md-4">
                            <label>Phone</label>
                            <div class="agent-details form-control">{{formatPhoneNumber(agent.phone)}}</div>                            
                        </div>
                    <!-- </div>
                     <div class="row mt-3"> -->
                        <div class="col-md-4 mt-1">
                            <label>Email</label>                           
                            <div class="agent-details form-control">{{agent?.email?.toLowerCase()}}</div>
                        </div>
                        <div class="col-md-4 mt-1" *ngIf="agent.address[0].fullAddress">
                            <label>Address</label>
                            <div class="agent-details form-control">{{ agent.address[0].fullAddress ? agent.address[0].fullAddress : "" }}</div>
                        </div>
                        <div class="col-md-2 mt-1" *ngIf="agent.address[0].city">
                            <label>City</label>
                            <div class="agent-details form-control">{{ agent.address[0].city ? agent.address[0].city : "" }}</div>                            
                        </div>
                        <div class="col-md-2 mt-1" *ngIf="agent.address[0].state">
                            <label>State</label>
                            <div class="agent-details form-control">{{ agent.address[0].state ? agent.address[0].state : "" }}</div>                            
                        </div>
                    <!-- </div>
                    <div class="row mt-3"> -->
                        <div class="col-md-4 mt-1" *ngIf="agent.address[0].zip">
                            <label>ZIP Code</label>                           
                            <div class="agent-details form-control">{{ agent.address[0].zip ? agent.address[0].zip : "" }}</div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-lg-6">
                            <div class="agent-details" tabindex="0"><div>Phone:</div> <div></div></div>
                            <div class="mt-3 agent-details" tabindex="0"><div>Email:</div> <div></div></div>
                            <div class="mt-3 agent-details" tabindex="0"> <div *ngIf="agent.address[0].fullAddress">Address:</div>
                            <div *ngIf="(agent.address[0].city||agent.address[0].state||agent.address[0].zip) &&!agent?.address[0]?.fullAddress">Address:</div>
                            <div class="mob-mar-left">
                            <div class="mb-3 address-col" *ngIf="agent.address[0].fullAddress">{{ agent.address[0].fullAddress ? agent.address[0].fullAddress : "" }}</div>
                            <div class="mb-3 agent-details-sub" tabindex="0" *ngIf="agent.address[0].city"><div class="col-md-2">City:</div> <div class="col-md-4 text-start mob-mar-bot">{{ agent.address[0].city ? agent.address[0].city : "" }}</div></div>
                            <div class="mb-3 agent-details-sub" tabindex="0" *ngIf="agent.address[0].state"><div class="col-md-2">State:</div> <div class="col-md-6 text-start mob-mar-bot">{{ agent.address[0].state ? agent.address[0].state : "" }}</div></div>
                            <div class="agent-details-sub" tabindex="0" *ngIf="agent.address[0].zip"><div class="col-md-2">ZIP Code:</div> <div class="col-md-4 text-start mob-mar-bot">{{ agent.address[0].zip ? agent.address[0].zip : "" }}</div></div>
                            </div>
                          </div>
                        </div>
                    </div> -->
                    <div class="row mt-2">
                           <div class="title">           
                     <h2 tabindex="0">Memberships</h2>                 
                    </div>
                 </div>
    
                 <div class="row">
                    <div class="flex-wrap-mls">
                     <div *ngFor="let agentMls of agent.mls; index as i" class="flex-wrapper" [ngStyle]="{'margin-right': isEditing() ? 'unset':'15px'}">                                
                        <div class="label-wrapper">
                            <mat-label tabindex="0">MLS Memberships:</mat-label>
                        </div>
                        <div tabindex="0">{{agentMls.mlsName}}</div>                                    
                        <mat-select *ngIf="isEditing()" [(value)]="agent.mls[i].mlsName" (selectionChange)="onSelectedMls(i, $event)" tabindex="0">
                            <mat-option *ngFor="let mls of mlsList" [disabled]="isMlsAdded(mls)" [value]="mls.mlsName">{{mls.mlsName}}</mat-option>
                        </mat-select>                              
                    </div>
                </div>
                 </div>
                    <div class="row mt-4" >
                        <div class="title" *ngIf="hasLicenseWithLicenseNumber()" >
                            <h2 tabindex="0">License</h2>
                        </div>
                    </div>
                    <ng-container *ngIf="hasLicenseWithLicenseNumber()">

                        <div class="row location" *ngFor="let license of agent.license; index as i" tabindex="0">
                            <div class="mb-2 flex-wrapper">
                                <div class="pr-30">{{license.licenseNumber}}</div>
                                <div>{{license.licenseRegion}}</div>                       
                            </div>
                        </div>
                    </ng-container>
                    <mat-card-actions *ngIf="!isSuperAdmin">
                        <div style="margin-top: 2%;">
                            <span (click)="onRequestAccess()" class="request-access-link">Request Access to additional MLS <i class="bi bi-arrow-right" style="vertical-align: text-top;"></i></span>
                        </div>
                    </mat-card-actions>  
                </div>
            </div>
        </div>
    </div>
    </div>
    <app-spinner [show]="showSpinner"></app-spinner>
    <div id="modal" class="modal" *ngIf="openModal">
        <div class="modal-content">
            <span (click)="onCloseModal($event)" class="close" title="Close Modal"><img src="assets/close-icon.png" alt="Close Icon" title="Close" class="close-icon"></span>
                <div class="logo-modal-position">
                    <img src="./assets/logo-symbol.png" alt="Livepad logo-symbol">
                    <h5 class="modal-title">Request MLS</h5>
                   </div>
                <form #f="ngForm" (ngSubmit)="onSendRequest(f)" novalidate>
                    <div class="col-md-12 Colpad">
                        <mat-label htmlFor="selectedOrg" class="mt-3 nameLabel" tabindex="0">Select Your Organization <span style="color: #ED4036; filter: contrast(1);">*</span></mat-label>
                        <mat-select class="inputTag form-control" tabindex="0" id="selectedOrg" aria-labelledby="selectedOrg" name="selectOrg"  ngModel #selectOrg="ngModel" required style="background:transparent;"
                          (selectionChange)="onOrganizationSelected($event)" [panelClass]="'customAgentMLS'">
                          <mat-option *ngFor="let org of orgList" [value]="org" autocomplete="off" class="mt-3 inputTag" style="background:transparent;">
                            {{ org.name }}
                          </mat-option>
                        </mat-select>
                        <div *ngIf="selectOrg?.errors?.['required'] && (selectOrg.dirty || selectOrg?.touched)">
                            <p class="text-danger" *ngIf="f.invalid">Organization is required</p>
                        </div>
                      </div>
                     
                      <div class="col-md-12 Colpad">
                        <label class="mt-3 nameLabel" tabindex="0">Select Your MLS <span style="color: #ED4036; filter: contrast(1);">*</span></label>
                        <mat-select  multiple class="inputTag form-control" tabindex="0" id="yourMls2" name="selectMls" ngModel  #selectMls="ngModel" required aria-labelledby="yourMls2"
                        [panelClass]="'mlsClass customMLSoption'"
                          style="background:transparent;">
                          <mat-option *ngFor="let mls of mlsList" [value]="mls" autocomplete="off" class="mt-3 inputTag"
                            style="background:transparent;">
                            {{mls.mlsName}}
                          </mat-option>
                        </mat-select>
                        <div *ngIf="selectMls?.errors?.required && (selectMls.dirty || selectMls.touched)">
                            <p class="text-danger" *ngIf="f.invalid">MLS is required</p>
                        </div>
                      </div>
     
                    <div class="row">
                        <div class="col-md-12">
                        <div class="signBtn">
                            <button type="submit" tabindex="0" class="btn btn-primary" [disabled]="!f.form.valid"> SEND REQUEST</button>
                            <button  tabindex="0" class="btn btn-secondary" (click)="onCloseModal($event)"> CANCEL
                        </button></div>
                    </div>
                </div>
                </form>
           
        </div>
    </div>
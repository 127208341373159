<!-- <div class="tour-list-container">
<mat-form-field class="filter-wrapper">
  <mat-label>Filter</mat-label>
  <input matInput [(ngModel)]="inputValue" (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
</mat-form-field>
</div> -->

<div class="tour-list-container">
<div class="filter-wrapper">
  <label>Search</label>
  <input [(ngModel)]="inputValue" (keyup)="applyFilter($event)" id="cliSearch" aria-labelledby="cliSearch" #input class="form-control">
</div>
</div>
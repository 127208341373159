<div class="header-wrapper">
	<div [ngClass]="{'headImage row': !isConfirmation, 'headImageNew': isConfirmation || forgetPassword || galleryPage,'loginOPtion': loginService.authorization?.token}">
			<span *ngIf="loginService.authorization?.token != null && !isDashboardPage && !isConfirmation && !galleryPage && !forgetPassword" tabindex="0" (click)="handleBackButtonClick()" class="back-button"><strong>&lt;</strong>&nbsp;Back</span>
			<p class="header-logo-container">
				<button *ngIf="!isConfirmation && !forgetPassword && !galleryPage" (click)="!isConfirmation&&!passwordchange&&!galleryPage ? navigateToDashboard($event) : null" class="header-logo-img" tabindex="0" title="Livepad Logo">
					<img src="assets/livepad-logo1.svg" alt="Livepad Logo">
				</button>
				<button *ngIf="isConfirmation || forgetPassword || galleryPage" class="header-logo-new" tabindex="0" title="Livepad Logo">
					<img src="assets/livepad-logo1.svg" alt="Livepad Logo">
				</button>
			</p>
			<button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
				<span class="navbar-toggler-icon"><b class="adafix">.</b></span>
			</button>
			<div *ngIf="!isConfirmation && !forgetPassword && !galleryPage" id="mynavbar" class="collapse avatar-align-right" (click)="!isConfirmation&&!passwordchange ? pageCtaClick($event,'log_in') : null">
				<div class="account-container" *ngIf="loginService.authorization?.token != null"><img class="" src="./assets/account-setting.png" onerror="this.src='./assets/avatar.jpg'" (click)="!isConfirmation&&!passwordchange ? showAgentOptions() : null"
				alt="Agent Picture" tabindex="0"/>
				<span  class="login-title" tabindex="0"(click)="!isConfirmation&&!passwordchange ? showAgentOptions() : null">Account Settings</span> <!--(click)="!isConfirmation&&!passwordchange ? showAgentOptions() : null"-->
				<ng-container *ngIf="showAgentOptionsFlag">
					<div class="agent-options">
					<p (click)="routeToAccount();pageCtaClick($event)" role="selection">Profile</p>
					<p (click)="changePassword();pageCtaClick($event)" role="selection">Change Password</p>
					<p (click)="routeToWishList();pageCtaClick($event)" role="selection">Wishlist</p>
					</div>
				</ng-container>
				</div>
				<span *ngIf="loginService.authorization?.token == null" class="Subscribe_text" tabindex="0"><a routerLink="/subscription" class="subscribe-link" tabindex="0">Subscribe</a></span>
				<span *ngIf="loginService.authorization?.token == null" class="signin_text"(click)="!passwordchange ? handleButtonText($event) : null" tabindex="0">Sign in</span>
				<span *ngIf="loginService.authorization?.token != null" class="signout_text" (click)="handleSignOutClick()" tabindex="0" title="Log out">Log out</span>
			</div>
    </div>
</div>

<mat-card>
	<mat-card-title>{{title}}</mat-card-title>
	<mat-card-subtitle>{{ subtitle() }}</mat-card-subtitle>

	<div class="header-container">

		<div class="header-text">
			<!-- <div class="bold">{{ sendingAgentName() }}</div>  -->
			<div tabindex="0" class="heading1">{{ headerText() }}</div>
		</div>
		<app-property-card *ngIf="confirmationRequest != undefined" [property]="confirmationRequest.showings.property"></app-property-card>

		<!-- <h1 *ngIf="!isConfirmed()" class="appointment-date" tabindex="0">{{ dateText() }}</h1> -->

	</div>

	<mat-card-content>

		<div confirmed-already *ngIf="isConfirmed()" class="verified-container">
			<h1 tabindex="0">Viewing time confirmed!</h1>
			<h2 tabindex="0">{{ appointmentDateTime() }}</h2>
			<mat-icon class="verified">verified</mat-icon>
		</div>

		<div responded *ngIf="hasResponded()" class="verified-container">
			<h1 tabindex="0">Your response was sent!</h1>
			<mat-icon class="verified">verified</mat-icon>
		</div>

		<div confirmation-action *ngIf="shouldShowButtons()">
			<!-- <app-big-ass-button class="big-ass-button" [buttonStyle]="buttonStyle" [buttonTitle]="firstButtonTitle()" [buttonImage]="logo"
				[buttonImageStyle]="logoStyle" (buttonAction)="onFirstButtonAction()" [disabled]="shouldDisableConfirm()">
			</app-big-ass-button>
			<app-big-ass-button class="big-ass-button" [buttonStyle]="buttonStyle" buttonTitle="Suggest another time?"
				[buttonImage]="logo" [buttonImageStyle]="logoStyle"	(buttonAction)="onSecondButtonAction()">
			</app-big-ass-button> -->
			<app-big-ass-button class="big-ass-button" [buttonStyle]="buttonStyle" [buttonTitle]="firstButtonTitle()"
				[buttonImageStyle]="logoStyle" (buttonAction)="onFirstButtonAction()" [disabled]="shouldDisableConfirm()">
			</app-big-ass-button>
			<app-big-ass-button class="big-ass-button" [buttonStyle]="buttonStyle" buttonTitle="Suggest another time?"
			 [buttonImageStyle]="logoStyle"	(buttonAction)="onSecondButtonAction()">
			</app-big-ass-button>
		</div>

	</mat-card-content>
	<mat-card-footer>
		<div class="reply-container" *ngIf="showReply && shouldShowButtons()">
			<form class="reply-form" >
				<label for="selectDateTime" style="font-size: 18px;">Suggest a new time</label>
				<div class="suggest-time-input" >
					<div class="input-group input-group-sm mb-3 timepicker-input-width" aria-label="time picker">
						<input
						class="form-control custom-height-input"
						placeholder="mm-dd-yyyy hh:mm:ss "
						[owlDateTime]="dt3"
						[owlDateTimeTrigger]="dt3"
						[value]="suggestedDateControl" 
						[formControl]="suggestedDateControl"
						[min]="today"
						[max]="nextYear"
						id="selectDateTime"
						(dateTimeChange)="onDateTime($event,confirmationRequest)"
						/>
						<span class="input-group-text bg-white" [owlDateTimeTrigger]="dt3" >
							<mat-icon aria-hidden="false" aria-label="calender SVG icon">
								calendar_today
							</mat-icon>
						</span>
						<owl-date-time [hour12Timer]="'true'" #dt3></owl-date-time>
					</div>
				</div> 
				<!-- <mat-form-field class="suggest-time-input" appearance="outline">
					<mat-label>Suggest another time here:</mat-label>
					<input matInput type="datetime-local" min="{{ today | date: 'yyyy-MM-ddTHH:mm' }}" max="{{ nextYear | date: 'MM-dd-yyyyTHH:mm' }}" [matDatepicker]="picker" (focus)="picker.open()" (click)="picker.open()" [formControl]="suggestedDateControl">
					<mat-datepicker #picker></mat-datepicker>
				</mat-form-field> -->

				<!-- <label>Add a reply to {{sendingAgentName()}}?</label>
				<mat-form-field class="reply-form-field" appearance="outline"> -->
					<!-- <mat-label>Suggest another time here:</mat-label> -->
					<!-- <textarea matInput placeholder="eg: perhaps a little earlier?" [formControl]="replyControl">
					</textarea> 
				</mat-form-field>-->

				<!-- <button color="primary" [disabled]="shouldDisableSubmit()" class="reply-submit-button" mat-raised-button color="basic" (click)="onSubmitReply(confirmationRequest)" aria-label="Submit a reply">Submit</button> -->
				<button color="primary" [disabled]="!disableButton" class="reply-submit-button" mat-raised-button color="basic" (click)="onSubmitReply(confirmationRequest)" aria-label="Submit a reply">SUBMIT</button>
			</form>
		</div>
		<mat-error class="left-align" *ngIf="error">{{error}}</mat-error>
	</mat-card-footer>

</mat-card>

<app-spinner [show]="showSpinner"></app-spinner>

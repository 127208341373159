<mat-card class="property-card" (click)="viewProperty()">
	<div class="container">
		<div class="address">{{property.address.fullAddress}}</div>
		<img class="property-thumbnail" src="{{ firstOf(property.propertyPhotos)?.url }}" alt="Property thumbnail" onerror="this.src='./assets/no_photo_455x340.jpg'" />
		<div class="price-detail">
			${{ addCommasToPrice(property.propertyFeatures['featureData'].ListPrice) }}
		</div>
		<div class="small-details bottom">
			{{ property.propertyFeatures['featureData'].BedroomsTotal }}bd / {{ bathroomCalculation(property) }}ba
			<div class="divider"></div>
			{{ property.propertyFeatures['featureData'].LivingArea }} Sq Ft
		</div>
	</div>
</mat-card>
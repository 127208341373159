import { Directive, ElementRef, EventEmitter, HostListener, Output,Renderer2} from '@angular/core';

@Directive({
  selector: '[Formatphone]'
})
export class PhoneDirective {
  @Output() pasteError: EventEmitter<boolean> = new EventEmitter<boolean>();

  // private regex: RegExp = new RegExp('^[0-9]{0,10}$');
  // private regex: RegExp = new RegExp('^(?=.*[1-9])[0-9]{0,10}$');
  private regex: RegExp = new RegExp('^[1-9a-zA-Z][0-9]{0,10}$');

  constructor(private elementRef: ElementRef,
    private el: ElementRef,
    private renderer: Renderer2
    ) { }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const inputVal: string = this.elementRef.nativeElement.value;
    if (event.key === 'Backspace') {
      return;
    }
    const inputValue: string = inputVal.concat(event.key);
    if (inputValue && !String(inputValue).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event) {
    const clipboardData = (event.originalEvent || event).clipboardData.getData('text/plain');
    if (clipboardData) {
      const plainData = clipboardData.replace(/-/g, '');
      if(plainData.length>10){
        this.pasteError.emit(false);
        event.preventDefault();
      }
      else{
        const regEx = new RegExp('^[0-9]*$');
        if (!regEx.test(plainData)) {
          this.pasteError.emit(false);
          event.preventDefault();
        }
      }
    }
  }

  @HostListener('ngModelChange', ['$event']) onInputChange(event) {
    if(event){
      const inputVal = event.replace(/\D/g, '').substring(0, 10);
      if (inputVal) {
        this.elementRef.nativeElement.value = inputVal.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      } else {
        this.elementRef.nativeElement.value = '';
      }
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ToursService } from './services/tours.service'
import { Actions } from './actions'
import { environment } from '@env/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SeoService } from './services/seo.service';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
declare const gtag: Function;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

	title = 'LivePad';

	sideNavMode = "over"
	hideWebView:boolean=true;
	constructor( private toursService: ToursService,private router: Router ,private seoService: SeoService) {
		this.router.events.subscribe({next:(event) => {
			if (event instanceof NavigationEnd) {
    			const url = event.url;
				const getPageName=(item)=>{
					let uriTitle=''
					  if(item.includes('/property/')){
						  uriTitle='property-details';
					  }else if(item=='/'){
						  uriTitle=this.title;
					  }else if(item.includes('/tour?')){
						uriTitle='tour-details';
					  }else if(item.includes('/my-document')){
						uriTitle='Documents';
					  }else if(item.split('/')[1].includes('?')){
						uriTitle=item.split('/')[1].split('?')[0];
					  }else{
						uriTitle=item.split('/')[1];
					  }
					return uriTitle;
				  }
				const pageSeoData= {meta_name_title:getPageName(url),
					// url!=='/'?url.includes('/property/')?'property-details':url.split('/')[1]:this.title,
					meta_name_keywords:'',
					meta_name_description:'LivePad',
					meta_name_author:'',
				}
				this.seoService.setMetaData(pageSeoData)
				gtag('config', environment.gtagId, { 'page_path': event.urlAfterRedirects==='/'?'/landing':event.urlAfterRedirects,'page_title': event.urlAfterRedirects==='/'?'landing':event.urlAfterRedirects.split('/')[1] });
				 // Extract screen name
				 const screenName = event.urlAfterRedirects;
				 // Send data to Google Analytics
				 gtag('event', 'screen_view', {
				   'app_name': 'LivePad',
				   'screen_name': screenName,
				   'title': pageSeoData.meta_name_title,
				   'page_title': event.urlAfterRedirects==='/'?'landing':event.urlAfterRedirects.split('/')[1]
				 });
			  this.hideWebView = !url.includes('/client-complete');
			}
		  }});
		if (environment.devProd) {
			const state1 = localStorage.getItem('logState');
			if (!state1 || state1 !== 'enabled') {
			  console.log = () => {};
			  console.warn = () => {};
			}
		  }
	}

	ngOnInit() {

  this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: any) => {
          if(event.url.includes('my-document')){
			// const getPathParams=localStorage.getItem('panel_path');
			// if(getPathParams){
            //     this.router.navigate(['/my-document',getPathParams])
            // }else{
            //     this.router.navigate(['/my-document'])
            // }
			this.router.navigate(['/my-document'])
			localStorage.setItem('panel_path', '');
          }
	})
		this.fetchRequestActions()
		// this.emptyButton(){
		// 	('.carousel-indicators button').html('<b></b>')
		// }
	}

	/**
 	 Fetch all the Request Actions available
	*/	
	fetchRequestActions() {

		this.toursService
			.fetchRequestActions()
			.subscribe(response => { 
		
					// Assume for alpha stage that an error means logged out
					if ( response["errors"] != null ) {
						// console.error(response["errors"] )
						return
					}  


					// Dynamically create typed members
					response["data"].requestActions
						.forEach( x => {
							let key = x.name.replace(/\s/g, '')
							Actions[key] = x
						})

		})
	}
}


// emptyButton(){
//     setTimeout(() => {
//       var spans = document.querySelectorAll('.carousel-indicators button');
//       if(this.isValueEmpty(spans)){
//         if(spans.length > 0){   
//           spans[0].innerHTML="nbsp;nbsp;";     
//         }
//       }
//     },3000);
//   }  



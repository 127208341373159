<div style="text-align: center;">
    <button class="btn btn-text viewallClient" (click)="viewClients()" tabindex="0"> View All Clients <i class="bi bi-chevron-right"></i></button>
    <br>
    <button class="btn btn-text add-to-tour" (click)="onAddToTour(client)" tabindex="0"> Add to Tour </button>
</div>
<div class="mb-4">
    <div class="content-container margin-container">
        <div class="title">              
                <h1 tabindex="0">
                 <!-- <img src="assets/client-icon-new.svg" alt="Client Icon" width="40px" tabindex="0">  -->
                    <span>{{title}}</span>
                </h1>
                <a  class="editprofile" (click)="onClick($event)" tabindex="0">
                    <label>Edit</label> <i class="bi bi-pencil-fill icon-blue" title="click here to edit"></i></a>
            </div>

        <div class="clientForm mt-2">
            <div class="row">
                <div class="client-picture-wrapper">
                    <div >
                      <!-- <mat-label tabindex="0">Change Image</mat-label> -->
                      <img class="image" alt="Client avatar" [src]="isValidImageUrl(client.picture) ? client.picture : './assets/avatar.jpg'" tabindex="0" />
                    </div>
                </div>
                <div class="form-field-wrapper formText">

                    <!-- <div class="row">
                        <div class="col-md-4 mobileWidth">
                            <div class="agent-details" tabindex="0">
                                <div>Phone:</div>
                                <div>{{ formatPhoneNumber(client.phone) }}</div>
                            </div>
                            <div class="mt-3 agent-details" tabindex="0">
                                <div>Email:</div>
                                <div>{{client.email}}</div>
                            </div>
                            <div class="mt-3 agent-details" tabindex="0">
                                <div *ngIf="client.address[0].fullAddress||client.address[0].city||client.address[0].state||client.address[0].zip">Address:</div>
                                <div>{{ client.address[0].fullAddress ? client.address[0].fullAddress : "" }}
                                    <div class="mt-3 agent-details-sub sub-width" tabindex="0">
                                        <div *ngIf="client.address[0].city">City:</div>
                                        <div>{{ client.address[0].city ? client.address[0].city : "" }}</div>
                                    </div>
                                    <div class="mt-3 agent-details-sub" tabindex="0">
                                        <div *ngIf="client.address[0].state">State:</div>
                                        <div>{{ client.address[0].state ? client.address[0].state : "" }}</div>
                                    </div>
                                    <div class="mt-3 agent-details-sub" tabindex="0">
                                        <div *ngIf="client.address[0].zip">ZIP Code:</div>
                                        <div>{{ client.address[0].zip ? client.address[0].zip : "" }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="row client-profile">
                    <div class="col-md-7">
                        <h2>Personal Info</h2>
                        <div class="row">
                         <div class="col-md-6">
                        <div class="form-group">
                            <label>First Name</label>
                        <div class="form-control">{{client.firstName}}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                        <label>Last Name</label>
                        <div class="form-control">{{client.lastName}}</div>
                        </div>
                    </div>
                    </div>
                    <div class="row">
                         <div class="col-md-6">
                        <div class="form-group">
                        <label>Phone</label>
                        <div class="form-control">{{formatPhoneNumber(client.phone)}}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                        <label>Email</label>
                        <div class="form-control">{{client?.email?.toLowerCase()}}</div>
                        </div>
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6" *ngIf="client.address[0].fullAddress">
                            <div class="form-group">
                            <!-- <label *ngIf="(client.address[0].city||client.address[0].state||client.address[0].zip) &&!client?.address[0]?.fullAddress">Address</label> -->
                            <label>Address</label>
                            <div class="form-control" *ngIf="client.address[0].fullAddress">{{ client.address[0].fullAddress ? client.address[0].fullAddress : "" }}</div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="client.address[0].city">
                            <div class="form-group">
                            <label >City</label>
                            <div class="form-control">{{ client.address[0].city ? client.address[0].city : "" }}</div>
                            </div>
                        </div>
                        <!-- </div>
                        <div class="row"> -->
                        <div class="col-md-6" *ngIf="client.address[0].state">
                            <div class="form-group">
                            <label >State</label>
                            <div class="form-control">{{ client.address[0].state ? client.address[0].state : "" }}</div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="client.address[0].zip">
                            <div class="form-group">
                            <label >ZIP Code</label>
                            <div class="form-control">{{ client.address[0].zip ? client.address[0].zip : "" }}</div>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div class="col-md-5 px-md-5">
                        <div class="row" *ngIf="client.clientcategory && client.clientcategory.length > 0">
                        <div class="title">           
                            <h2 tabindex="0">Lifestyle</h2>                 
                        </div>
                    </div>

                    <div class="row" style="padding-top: 2%;">
                        <div class="col-md-12 col-sm-12">
                            <mat-accordion>
                                <mat-expansion-panel *ngFor="let category of client.clientcategory" class="matExpansionList">
                                <mat-expansion-panel-header>
                                    <div class="category-title">{{ category.category.name }}</div>
                                    
                                </mat-expansion-panel-header>
                                <div class="DesktopMar">
                                    <ng-container *ngIf="category.clientsubcategory && category.clientsubcategory.length > 0">
                                    <div *ngFor="let subcategory of category.clientsubcategory;let i =index">
                                        {{ subcategory.subcategory.name }}{{ i === category.clientsubcategory.length - 1 ? '' : ',' }}
                                    </div>
                                    </ng-container>
                                </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    </div>
                    <div class="row">
                        <div class="client-profile">           
                            <h2 tabindex="0">Timeframe</h2>                 
                        </div>
                    </div>
                    <div class="row">
                        <mat-button-toggle-group #group name="clientTimeframe" aria-label="Client-Timeframe" class="btn"
                            [value]="client.timeframe" [disabled]="true" tabindex="0">
                            <mat-button-toggle *ngFor="let timeframe of timeframes" [value]="timeframe.id" class="btn timeframe-option"
                                [ngClass]="{'selected': group.value === timeframe.id}">
                                {{timeframe.name}}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
                    
                    

                    <!-- <div class="row">
                        <div class="col-md-4 mobileWidth">
                            <div class="agent-details" tabindex="0"><div>Phone:</div> <div></div></div>
                            <div class="mt-3 agent-details" tabindex="0"><div>Email:</div> <div></div></div>
                            <div class="mt-3 agent-details" tabindex="0"> <div *ngIf="client.address[0].fullAddress">Address:</div>
                            
                            <div class="mob-mar-left">
                            <div class="mb-3 address-col" ></div>
                            <div class="mb-3 agent-details-sub" tabindex="0" ><div class="col-md-2">City:</div> <div class="col-md-4 text-start mob-mar-bot"></div></div>
                            <div class="mb-3 agent-details-sub" tabindex="0" ><div class="col-md-2">State:</div> <div class="col-md-6 text-start mob-mar-bot"></div></div>
                            <div class="agent-details-sub" tabindex="0"><div class="col-md-2">ZIP Code:</div> <div class="col-md-4 text-start mob-mar-bot"></div></div>
                            </div>
                          </div>
                        </div>
                    </div> -->
                    <!-- <div class="row">
                        <div tabindex="0" class="col-md-12 mobileWidth" *ngIf="client.clientcategory && client.clientcategory.length > 0">
                            <div class="mt-2 agent-category" tabindex="0">
                                <div>Category & &nbsp;Sub-Category:</div>
                                <div *ngFor="let category of client.clientcategory">
                                    <div class="category-title mobileMar"> {{ category.category.name }}</div>
                                    <div class="DesktopMar">
                                        <ng-container *ngIf="category.clientsubcategory && category.clientsubcategory.length > 0">
                                            <ng-container *ngFor="let subcategory of category.clientsubcategory;let i =index">
                                                <div style="width: fit-content;">  {{ subcategory.subcategory.name }}{{ i === category.clientsubcategory.length - 1?'':','}}</div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    

                    
                 
                </div>
            </div>
               <div class="row" *ngIf="client?.properties?.length>0">
                <div class="section-header mt-5">
                    <div class="title-bar">                               
                        <h2><span tabindex="0">Touring Properties</span></h2>
                    </div>      
                </div>    
                <div class="favorites-container">
                    <app-favorite-property-card 
                        *ngFor="let property of client?.properties" 
                        [client]="client" 
                        [property]="property?.property"
                        [propertyRatings]="property?.propertyRatings"
                        [clientFavorites]="property?.clientFavorites">  
                    </app-favorite-property-card>        
                </div>
                <app-property-list 
                    *ngIf="showTrailingProperties()"
                    [properties]="trailingProperties"
                    [showCheckbox]="false"
                    [showAddToTours]="false"> 
                </app-property-list>
                <app-spinner [show]="showSpinner"></app-spinner>
                </div>
        </div>
    </div>
</div>
<app-spinner [show]="showSpinner"></app-spinner>


import { Component, OnInit, Input,ElementRef } from '@angular/core';
import { User } from '../user';
import { LoginService } from '../services/login.service';
import { BigAssButtonComponent } from '../big-ass-button/big-ass-button.component';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';

@Component({
  selector: 'app-header-nav-bar',
  templateUrl: './header-nav-bar.component.html',
  styleUrls: ['./header-nav-bar.component.css'],
})
export class HeaderNavBarComponent implements OnInit {
  @Input()
  drawer: any;

  user: User;

  isDashboardPage = false;

  showAgentOptionsFlag = false;

  logo = 'assets/login-icon.svg';

  showSignOutText = false;
  isConfirmation = false;
  passwordchange = false;
  galleryPage = false;
  forgetPassword = false;

  buttonStyle = {
    "height": "44px",
		"width": "50px",
  };
  currentRouteUrl:string;


  constructor(
    public loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private gaService:GoogleAnalyticsService,
    private elRef: ElementRef
  ) {
    // Set initial
    this.user = this.loginService.user;

    // Watch for changes
    this.loginService.userChange.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    document.body.addEventListener('click', this.handleDocumentClick);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.closeAgentOptions();
        const url = event.url;
        const getPageName=(item)=>{
          let uriTitle=''
            if(item.includes('/property/')){
                uriTitle='property-details';
            }else if(item=='/'){
                uriTitle='LivePad';
            }else if(item.includes('/tour?')){
              uriTitle='tour-details';
            }else if(item.split('/')[1].includes('?')){
              uriTitle=item.split('/')[1].split('?')[0];
            }else{
              uriTitle=item.split('/')[1];
            }
          return uriTitle;
        }
        this.currentRouteUrl=getPageName(url)
        this.isDashboardPage = url === '/dashboard';
        this.isConfirmation = url.includes('/confirmation-request');
        this.passwordchange = url.includes('/forgot-password');
        this.galleryPage = url.includes('/gallery');
        this.forgetPassword = url.includes('/forgot-password');
      }
    });
  }

  handleBackButtonClick() {
    const fullName = `${this.user?.agent?.fullName}`.replace(/\s+/g, '-');
    this.gaService.sendCtaClickEvent({page_name:`${this.currentRouteUrl}-${fullName}`},{cta_type: 'icon', cta_location:'top_left_nav', cta_description:'back_arrow'});//cta 65
    this.location.back();
  }

  showAgentOptions() {
    this.showAgentOptionsFlag = !this.showAgentOptionsFlag;
    if (this.showAgentOptionsFlag) {
    this.showSignOutText = false;
    }
  }

  handleDocumentClick = (event: MouseEvent) => {
    // Check if the click target is outside of the agent options
    if (!this.elRef.nativeElement.contains(event.target)) {
      // If outside, close the agent options
      this.closeAgentOptions();
    }
  };

  closeAgentOptions() {
    this.showAgentOptionsFlag = false;
    this.showSignOutText = false;
  }

  routeToAccount() {
    // if (this.user?.name == null) {
    if (this.loginService.authorization?.token == null) {
      this.router.navigate(['/login'], {
        queryParams: this.route.snapshot.queryParams,
      });
      return;
    }

    this.router.navigate([`/agent/${this.user.agent.link}`], {
      queryParams: this.route.snapshot.queryParams,
    });
    this.showAgentOptionsFlag = false;
  }
  routeToWishList() {
    if (this.loginService.authorization?.token == null) {
      this.router.navigate(['/login'], {
        queryParams: this.route.snapshot.queryParams,
      });
      return;
    }

    this.router.navigate([`/wishlist`], {
      queryParams: this.route.snapshot.queryParams,
    });
    this.showAgentOptionsFlag = false;
  }

  buttonTitle() {
    // if (this.user?.name == null) {
    if (this.loginService.authorization?.token == null) {
      return 'Log In';
    } else {
      return `Hello ${this.user.name}!`;
    }
  }

  handleButtonText(event:Event) {
    event.stopPropagation();
    if (this.loginService.authorization?.token == null) {
      this.router.navigate(['/login'], { queryParams: this.route.snapshot.queryParams })
      this.gaService.sendCtaClickEvent({page_name:this.currentRouteUrl},{cta_type: 'nav', cta_location:"top_right_nav", cta_description:'log_in'});//cta 5
      return;
    }
    this.showSignOutText = !this.showSignOutText; // toggle showSignOutText
    if (this.showSignOutText) {
      this.showAgentOptionsFlag = false; // Hide the agent options when showing Sign Out text
    }
  this.gaService.sendCtaClickEvent({page_name:this.currentRouteUrl},{cta_type: 'icon', cta_location:"top_right_nav", cta_description:'sign_out'});//cta 5
}

  handleButtonClick() {
    if (this.showSignOutText) {
      this.handleSignOutClick();
      return ;
    }
    this.showSignOutText = true;
  }

  handleSignOutClick() {
    this.gaService.sendCtaClickEvent({page_name:this.currentRouteUrl},{cta_type: 'label', cta_location:"top_right_nav", cta_description:"sign_out"});
    this.onSignOut();
    this.showSignOutText = false;
  }

  navigateToDashboard(event:Event) {
    event.stopPropagation();
    if (this.loginService.authorization?.token != null) {
      this.router.navigateByUrl('/dashboard');
    }
    else {
      // this.router.navigate(['login']);
      this.router.navigate(['/']);
    }
    this.pageCtaClick(event,'home')
  }

  changePassword() {
    this.router.navigate(['/change-password']);
    this.showAgentOptionsFlag = false; // Hide the agent options after selecting an option
  }

  onSignOut() {
		this.loginService.logout()
		this.router.navigate(['/login'], { queryParams: this.route.snapshot.queryParams })
    this.showSignOutText = false;
	}

  isValidImageUrl(url: string): boolean {
    // const regex = /^(https:\/\/d3o40wwl2vh905\.cloudfront\.net\/assets\/images\/avatar\/agents\/\d+_\d+\.(jpg|png|jpeg))|(blob:.+)$/i;
    const regex = /^(https:\/\/dkx2r6ndffbfa\.cloudfront\.net\/assets\/images\/(DEV|STAGE|UAT|PROD)\/avatar\/clients\/\d+_\d+\.(jpg|png|jpeg))|(blob:.+)$/i;
    return regex.test(url);
  }
  pageCtaClick(event:Event,text?:string){
		event.stopPropagation();
		const element = (event?.target as HTMLElement);
		const roleValue = text=='log_in'?'image':element.getAttribute('role') ?? 'nav';
		const elementName =text?text:element?.innerText.includes(' ')?element?.innerText?.trim().replace(/ /g, '_').toLowerCase():element?.innerText?.toLowerCase();
    const locationName =roleValue=='selection'?'nav_dropdown':'top_right_nav'
    console.log({page_name:this.currentRouteUrl},{cta_type:roleValue,cta_location:locationName,cta_description:elementName})
		this.gaService.sendCtaClickEvent({page_name:this.currentRouteUrl},{cta_type:roleValue,cta_location:locationName,cta_description:roleValue=="image"?'user_profile_picture':elementName})
	}
}

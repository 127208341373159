<mat-dialog-content>
	<h2 class="modal-cus-title"><img src="./assets/alert-icon1.svg" width="30px" alt="Alert Icon" class="alert-img-pad"> Alert</h2>
	<div class="my-5 marg-left">
	<p>
		{{message}}
	</p>
	<p *ngFor="let item of list">
		{{item}}
	</p>
</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button type="submit" class="btn btn-primary" color="primary" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
	<button type="reset" class="btn btn-secondary" (click)="onCloseClick()" tabindex="1">{{cancelButtonText}}</button>
</mat-dialog-actions>
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Client } from '../client'
import { ClientService } from '../services/client.service'
import { AddressComponent } from '../address/address.component'
import { ConfirmComponent } from '../confirm/confirm.component'
import { ConfirmListComponent } from '../confirm-list/confirm-list.component'

import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { bathroomCalculation, checkForAuthError } from '../utils'
import { ToursService } from '../services/tours.service'
import { PropertyService } from '../services/property.service'
import { Property } from '../propertySearch'

import { Tour } from '../tour'
import { ClientListComponent } from '../client-list/client-list.component'
import { SpinnerComponent } from '../spinner/spinner.component'
import { CheckmarkComponent } from '../checkmark/checkmark.component'
import { BaseComponent } from '../base/base.component'
import { ConfirmInputComponent } from '../confirm-input/confirm-input.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';



@Component({
	selector: 'app-client',
	templateUrl: './client.component.html',
	styleUrls: ['./client.component.css'],

})

export class ClientComponent extends BaseComponent {

	/**
	 List of clients fetched for the logged in Agent
	*/
	clients : Client[] = []

	/**
	 Error if create client or other server request failed
	*/
	error: string = null


	// Whether we show the full list of clients
	showClientList = false

	/**
	 A list of properties that have been passed along from an SSO login from MLS
	*/
	mlsList: [string] = null

	/**
	 Which MLS did the list originate from?
	*/
	mlsName: string = null

	/**
	 The properties as pulled from the mlsList query parameter
	*/
	trailingProperties: Property[] = []

	/**
	 Value for when the spinner should be shown
	*/
	showSpinner = false


	showCheck = false

	private tourFlowId = null

	constructor(
		protected loginService: LoginService,
		protected router: Router,
		protected route: ActivatedRoute,
		private propertyService: PropertyService,
		private clientService: ClientService, 
		private toursService: ToursService,
		private snackBar: MatSnackBar,
		private dialog: MatDialog,
		private gaService:GoogleAnalyticsService ) { 

			super( loginService, router, route )

	}

	ngOnInit(): void {

		this.showSpinner = true

		// Check for properties passed to us in queryParams
		this.fetchTrailingProperties()

		// Get list of clients
		this.clientService
			.fetchClients()
			.subscribe(clients => { 
		
				this.showSpinner = false

					// Assume for alpha stage that an error means logged out
					if ( clients["errors"] != null ) {

						this.error = checkForAuthError( clients["errors"], this.router )
						return
					}  


					this.clients = clients["data"]["clients"]
					this.clients.forEach(each => { each.checked = false })
					if (this.clients.length > 0) {
						this.showClientList = true;
					} else {
						this.showClientList = false;
					}
		})
	}

	/**
	 Are there properties following the user from an MLS entrance?
	*/
	showTrailingProperties() {
		return this.mlsList != null
	}

	/**
	 Check for a query param of `tour=<tour_id>`
	*/
	getTitle() {

		let map = this.route.snapshot.queryParams
		if ( map["tour"] != null ) {
			this.tourFlowId = map["tour"]
			return "Select client to add to this tour"
		}

		return "My Clients"
	}

	confirmDelete(list: string[]) {

		const dialogRef = this.dialog.open(ConfirmComponent, {
			data: {
				message: 'Are you sure want to delete these clients?',
				buttonText: {
					ok: 'DELETE',
					cancel: 'CANCEL'
				},
				list: list
			}
		});

		return dialogRef.afterClosed()

	}

	// Toggle showing full client list
	onShowExisting() {

		this.showClientList = this.showClientList == true ? false : true
	}

	/**
	 Show our checkmark completion animation. Auto closing.
	*/
	showCheckmark(next) {


		this.showCheck = true
		setTimeout(() => {

			this.showCheck = false
			if ( next != null ) {
				next(this)
			}
		}, 3000)

	}


	onDeleteClients(event) {

		let list = this.clients
			.filter( x => { return x.checked })
			.map( each => { return `${each.firstName} ${each.lastName}` })
		
		if ( list.length < 1 ) {
			this.error = "Please Select a client to delete"
			return
		}
		this.gaService.sendCtaClickEvent({page_name:'clients'},{ cta_type: 'icon', cta_location:event?.eventFrom, cta_description:"delete_client",client_ids:this.clients.filter( x => { return x.checked }).map( x => { return x.id })})
		this.confirmDelete(list)
			.subscribe((result) => {

				this.error = null
				 
				if (result.confirmed) {

					let idList = this.clients
						.filter( x => { return x.checked })
						.map( x => { return x.id })

					this.showSpinner = true
				 
					this.clientService
						.deleteClients(idList)
							.subscribe(clients => { 
						
									// Capture server error messages
									if ( clients["errors"] != undefined ) {

										this.showSpinner = false

										this.error = checkForAuthError( clients["errors"], this.router )
										return
									}

									this.clientService
									.fetchClients()
									.subscribe(clients => { 
								
											this.showSpinner = false

											// Assume for alpha stage that an error means logged out
											if ( clients["errors"] != null ) {
												this.error = checkForAuthError( clients["errors"], this.router )

												return
											}  

											this.showCheckmark(null)

											this.clients = clients["data"]["clients"]
											this.clients.forEach(each => { each.checked = false })

								})

						})
				} else {
					this.showSpinner = false
				}
			})


	}



	onDeleteClient(event) {
		let list = event.client
			.map( each => { return `${each.firstName} ${each.lastName}` })
		this.gaService.sendCtaClickEvent({page_name:'clients'},{ cta_type: 'icon', cta_location:event?.eventFrom, cta_description:"delete_client",client_ids:event.client.map( x => { return x.id })})
		this.confirmDelete(list)
			.subscribe((result) => {

				this.error = null
				 
				if (result.confirmed) {

					let idList = event.client
						.map( x => { return x.id })

					this.showSpinner = true
				 
					this.clientService
						.deleteClients(idList)
							.subscribe(clients => { 
						
									// Capture server error messages
									if ( clients["errors"] != undefined ) {

										this.showSpinner = false

										this.error = checkForAuthError( clients["errors"], this.router )
										return
									}

									this.clientService
									.fetchClients()
									.subscribe(clients => { 
								
											this.showSpinner = false

											// Assume for alpha stage that an error means logged out
											if ( clients["errors"] != null ) {
												this.error = checkForAuthError( clients["errors"], this.router )

												return
											}  

											this.showCheckmark(null)

											this.clients = clients["data"]["clients"]
											this.clients.forEach(each => { each.checked = false })

								})

						})
				} else {
					this.showSpinner = false
				}
			})
	}

	/**
	 Create a new client
	*/
	onAddClient() {
		this.gaService.sendCtaClickEvent({page_name:'clients'},{ cta_type: 'label', cta_location:"my_client", cta_description:"add_client"})
		this.router.navigate(['/new-client'], { queryParams: this.route.snapshot.queryParams })

	}

	routeToTours() {

		this.router.navigate(['/tours'], { queryParams: this.route.snapshot.queryParams })

	}




	/**
	 Allow the user to choose his tour 
	*/
	confirmTour(tourAdditions) {

		if ( this.tourFlowId != null ) {
			this.confirmFromTourFlow(tourAdditions)
			return
		}

		this.confirmFromTourList(tourAdditions)

	}

	/**
	 Using the queryParam tour id that was passed in here. No
	 selection from list is needed.
	*/
	confirmFromTourFlow(tourAdditions) {


		// Have him choose which
			const dialogRef = this.dialog.open(ConfirmComponent, {
			data: {
						message: 'Add these clients to your tour?',
						buttonText: {
					ok: 'ADD',
					cancel: 'CANCEL'
						},
						list: tourAdditions.map( x => `${x.firstName} ${x.lastName}` )
			},
			panelClass:'confirm-popup'
		})
		dialogRef.afterOpened().subscribe(()=>{	this.showSpinner = false;})
			dialogRef
				.afterClosed()
				.subscribe((result) => {

				this.error = null

				if (result && result.confirmed) {

					this.showSpinner = true
					

					let tourId = this.tourFlowId
					this.toursService.fetchTourSummary(tourId).subscribe((response) => {
					console.log("response of fetchTourSummary is",response['data']['tours'][0].clients);
					let tour_clients = response['data']['tours'][0].clients
					let clientIdList = tourAdditions.map( x => x.id )
					let existingClients = [];
					let newClients = [];

					clientIdList.forEach(clientId => {
						let existingClient = tour_clients.find(client => client.id === clientId);
						if (existingClient) {
							// Client already exists in the tour
							existingClients.push(existingClient);
						  } else {
							// Client is new and needs to be added to the tour
							newClients.push(clientId);
						  }
					});

					if (existingClients.length > 0) {
						let errorMessage = existingClients.map(client => `${client.firstName} ${client.lastName}`).join(', ');
						let message
						if(existingClients.length==1){
							message =`${errorMessage} is already added`;
						}
						else{
							message = `${errorMessage} are already added`;
						}
						this.snackBar.open(message, 'Close', {
							duration: 5000,
							horizontalPosition: 'center',
							verticalPosition: 'top'
						  });
						  this.showSpinner = false
					}

					if (newClients.length > 0) {
						this.clientService
							.addClientsToTour(newClients, tourId)
							.subscribe(showingTour => { 

								this.showSpinner = false

								// Assume for alpha stage that an error means logged out
								if ( showingTour["errors"] != null ) {
									this.error = checkForAuthError( showingTour["errors"], this.router )
									return
								}

									this.showCheckmark((self) => {
										self.routeToTours()
									})

						})
					}
				});
				}
				else {
					this.showSpinner = false;
				}
			})

	}


	confirmFromTourList (tourAdditions) {
		console.log("tourAdditions",tourAdditions)
		this.gaService.sendCtaClickEvent({page_name:'clients'},{ cta_type: 'icon',  cta_location:"clients", cta_description:"add_to_tour",client_ids:tourAdditions.map(item=>item?.id)})
		// Get the agent's tours
		this.toursService
			.fetchTours()
			.subscribe(tours => { 
		
				
				if ( tours["errors"] != null ) {
						this.error = tours["errors"][0].message
						this.showSpinner = false;
					return
				}  

				if (tours['data']['tours'].length === 0) {
					this.showCreateTourPopup(tourAdditions);
					return;
				}

				const filteredTours = tours['data']['tours'].filter((tour) => tour.tourCompleted === 0);

				// Have him choose which
				const dialogRef = this.dialog.open(ConfirmListComponent, {
					data: {
						message: 'Please select a tour',
						buttonText: {
							ok: 'SELECT',
							cancel: 'CANCEL',
							extra: 'CREATE NEW',
						},
						// list: tours["data"]["tours"].map( x => x.name ),
						list: filteredTours.map((x) => x.name),
						extraAction: () => {
							// An extra close call is probably not needed...
							dialogRef.close();
							this.showCreateTourPopup(tourAdditions);
						   },
					}
				})
				dialogRef.afterOpened().subscribe(()=>{	this.showSpinner = false;})
				dialogRef
					.afterClosed()
					.subscribe((result) => {

					this.error = null

					this.showSpinner = true
					//cta 59
					if(result  && result?.cancel){
						this.gaService.sendCtaClickEvent({page_name:`clients`},{cta_type: 'button', cta_location:'select_tour_popup', cta_description:'cancel'});
					}
					if (result && result.confirmed) {

						// let tourId = tours["data"]["tours"].filter( x => { return x.name == result.selection })[0]?.id
						let tourData = tours["data"]["tours"].filter(x => x.name == result.selection)[0];
						this.gaService.sendCtaClickEvent({page_name:`clients`},{cta_type: 'button', cta_location:'select_tour_popup', cta_description:'select', selection_option:tourData.name});//cta 57
						let clientIdList = tourAdditions.map( x => x.id )
						let tour_clients = tours["data"]["tours"].filter(x => x.name == result.selection)[0]?.clients;

							// Check which clients from clientIdList already exist in tour_clients
							let existingClients = [];
							let newClients = [];

							clientIdList.forEach(clientId => {
								let existingClient = tour_clients.find(client => client.id === clientId);
								if (existingClient) {
									// Client already exists in the tour
									existingClients.push(existingClient);
								  } else {
									// Client is new and needs to be added to the tour
									newClients.push(clientId);
								  }
							});

							if (existingClients.length > 0) {
								let errorMessage = existingClients.map(client => `${client.firstName} ${client.lastName}`).join(', ');
								let message
								if(existingClients.length==1){
									message =`${errorMessage} is already added`;
								}
								else{
									message = `${errorMessage} are already added`;
								}
								this.snackBar.open(message, 'Close', {
									duration: 5000,
									horizontalPosition: 'center',
									verticalPosition: 'top'
								  });
								  this.showSpinner = false
							}
							if (newClients.length > 0) {
							this.clientService
								.addClientsToTour(newClients, tourData.id)
								.subscribe(showingTour => { 
		
									this.showSpinner = false

									// Assume for alpha stage that an error means logged out
									if ( showingTour["errors"] != null ) {
										this.error = checkForAuthError( showingTour["errors"], this.router )
										this.showSpinner = false;
										return
									}

									this.showCheckmark((self) => {
										self.routeToTours()
									})

							})
					}
					} else {
						this.showSpinner = false
					}
				})

		})
	}

	showCreateTourPopup(tourAdditions) {
		this.gaService.sendCtaClickEvent({page_name:`clients`},{cta_type: 'button', cta_location:'select_tour_popup', cta_description:'create_new'});//cta 58
		const inputDialogRef = this.dialog.open(ConfirmInputComponent, {
		  data: {
			message: 'Please name your new tour',
			currentRoute:'client',
			buttonText: {
			  ok: 'CREATE',
			  cancel: 'CANCEL',
			},
			inputs: [
			  { name: 'Tour Name', value: null },
			  { name: 'Tour Description', value: null },
			],
		  },
		});
	  
		let runAfterClosed = (result) => {
		  if (result?.confirmed) {
			this.showSpinner = true;
	  
			let clientIdList =
			  tourAdditions != null ? tourAdditions.map((x) => x.id) : null;
	  
			// Create the new tour and get its id
			this.toursService.addTour(result.tour, null, clientIdList).subscribe((response) => {
			  // Rest of your existing code for creating the new tour...
			  if(response['errors'] != null) {
				this.error = response['errors'][0].message;
				// this.gaService.sendErrorDisplayEvent({page_name:'please_name_your_new_tour',message:this.error,type:"form_validation"})
				this.showSpinner = false;
				return;
			  }
			  this.navigateToTours();
			});
	  
			this.showSpinner = false;
			this.showCheckmark(null);
		  }
		};
	  
		runAfterClosed.bind(this);
	  
		inputDialogRef.afterClosed().subscribe(runAfterClosed);
	  }



	/**
	 Choose the tour to add the selected clients to
	*/
	onAddToTour(event) {

		this.error = null
		this.showSpinner=true;
		let clientAdditions = this.clients.filter( x => { return x.checked == true })
		if ( clientAdditions.length < 1 ) {
			this.error = "Please select client(s) to add to a tour"
			this.showSpinner=false;
			return
		}

		console.log("clientAdditions",clientAdditions);
		this.confirmTour(clientAdditions)
		
	}

	onAddTosingleTour(event) {
		this.error = null
		this.confirmTour(event.client)
	}

	navigateToTours() {
		this.router.navigate(['/tours']);
	}


	/**
	 If any mlsId's are present as query parameters, this will indicate we have been fed them
	 by an external MLS source
	*/
	fetchTrailingProperties() {

		// Extract any passed list of mls id's
		let map = this.route.snapshot.queryParams
		this.mlsList = map['mlsList']?.split(',')
		this.mlsName = map['mlsName']

		// Guard for no items present
		if ( this.mlsList == null || this.mlsName == null ) {
			return
		}

		this.propertyService
			.fetchPropertiesForMls( this.mlsList, this.mlsName )
			.subscribe(response => { 
		
							// Capture server error messages
					if ( response["errors"] != undefined ) {
						this.error = checkForAuthError( response["errors"], this.router )
						return
					}  

					this.trailingProperties = response["data"]["propertiesFromMls"]

		})
	}

	onRowSelected(clientId){
		console.log("object",clientId);
		this.gaService.sendCtaClickEvent({page_name:'clients'},{cta_type: 'list_item', cta_location:"list", cta_description:"client_list_item",client_id:clientId})
	}
}

<div class="content-bg">
<div class="container">
	<div class="content-container">
		<div class="row">
		<h1>
			<!-- <img src="assets/wishlist-icon.svg" alt="Wishlist Icon" tabindex="0" class="clients-icon-list m10"> -->
		Wishlist</h1>
		</div>
		<div class="row loading" *ngIf="!isDataLoaded">
			<div class="card">
				<div class="card__image"></div>
				<div class="card__content">
				  <h2></h2>
				  <p></p>
				</div>
			</div>
		</div>
		<div *ngIf="isDataLoaded && !wishList.length" class="row justify-content-center align-items-center">
			<p class="lead text-center no-properties">No properties in your wishlist</p>
		</div>
		<div class="row justify-content-center" else *ngIf="wishList?.length>0">
			<div class="col-md-3 mt-3" *ngFor="let item of wishList">
				<div class="wishlist-card">
					<h2 style="cursor: pointer;" class="truncate" [routerLink]="['/property',item?.listing_key]">{{item?.property?.UnparsedAddress}}</h2>
					<img [src]="item?.property?.propertyPhotos[0]?.url?item?.property?.propertyPhotos[0]?.url:'assets/no-property-available.png'" (error)="handleImageError(item?.property?.propertyPhotos[0])" alt="Property img" class="prop-img-width" style="cursor: pointer;" width="274" height="206" [routerLink]="['/property',item?.listing_key]"/>
					<div class="wish-price-wrapper"><label class="price">{{item?.property?.ListPrice| currency:'':'symbol':'.0'}}</label> 
						<button (click)="onAddToTour(item?.property);pageCtaClick($event,'add_to_tour',item?.listing_key)" role="icon">
							<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#0072C1" class="bi bi-plus" viewBox="0 0 16 16">
								<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
							</svg>
							<b class="adafix">.</b>
						</button>
					</div>
					<div class="wish-price-wrapper">
						<!-- <label>{{ item?.property?.BedroomsTotal || 'NA'}} bd / {{ bathRoomCalculation(item?.property) }} ba <span class="divide">|</span>{{ item?.property?.LotSizeAcres || 'NA' }} sqft</label> -->
						<div class="detail1">
							<div class="detail-wrap-mb">
								<div>
									<div class="detail-label"><img alt="bed-icon" src="assets/bed-mb-icon.png"> Beds</div>
									<div>{{item?.property?.BedroomsTotal?item?.property?.BedroomsTotal:'N/A'}}</div>
								</div>
								<div>
									<div class="detail-label"><img alt="bath-icon" src="assets/bath-mb-icon.png"> Bath</div>
									<div>{{ bathRoomCalculation(item?.property)  }}</div>
								</div>
								<div>
									<div class="detail-label"><img alt="sq-ft-icon" src="assets/sqft-mb-icon.png"> Sq&nbsp;Ft</div>
									<div>{{ item?.property?.LivingArea ? item?.property?.LivingArea:'N/A'}}</div>
								</div>
							</div>
						</div>
					</div>
					<button	class="check-btn close-btn-default"  (click)="removeWishlist(item);pageCtaClick($event,'remove',item?.listing_key)" role="button">
							<img src="assets/close-icon-default.svg" width="30" alt="Close Button">
							<b class="adafix">.</b>
						</button>
				</div>
			</div>
		</div>
	
	</div>
</div>
</div>
<app-spinner [show]="showSpinner"></app-spinner>
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { LoginService } from '../services/login.service'
import { ToursService } from '../services/tours.service'
import { PropertyService } from '../services/property.service'
import { ClientService } from '../services/client.service'

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Client, ClientFavorites } from '../client'
import { Property, PropertyRatings } from '../propertySearch'
import { bathroomCalculation, addCommasToPrice, firstOf } from '../utils'

@Component({
  selector: 'app-favorite-property-card',
  templateUrl: './favorite-property-card.component.html',
  styleUrls: ['./favorite-property-card.component.css']
})
export class FavoritePropertyCardComponent implements OnInit {

	@Input()
	property: Property

	@Input()
	client: Client

	@Input()
	propertyRatings: PropertyRatings;
	@Input()
	clientFavorites: ClientFavorites;

	// Expose to scope
	bathroomCalculation = bathroomCalculation
	addCommasToPrice = addCommasToPrice


	constructor( 
		private router: Router,
		private clientService: ClientService,
		private propertyService: PropertyService) { 

	}

	ngOnInit(): void {
	}


	/**
 	 Show a star icon if there is a client attached to this property and
 	 fill them in. The icon name `star` also matches a css class for coloring.
	*/
	getStarStatus(starIndex) {

		let lastRating = firstOf(this.propertyRatings)?.starRating ?? 0
		return Number(lastRating) >= starIndex ? "star" : "star_outline"		

	}

	favoriteStatus( ) {

		if ( firstOf(this.clientFavorites)?.favoriteStatus == 1 ) { return "favorite" }
		return "favorite_border"

	}

	favoriteLink() { 
		// return `./property/${this.property.listingKey}/client/${this.client.id}`
		return `/property/${this.property.listingKey}`
	}

	viewProperty() {

		this.router.navigate([`/property/${this.property.listingKey}/client/${this.client.id}`])
	}
	handleImageError(property: any) {
		property.url = "./assets/no_photo_455x340.jpg"; // Set the URL of the fallback image
	}
}
